import { Component, Input } from "@angular/core";

@Component({
    selector: "skeleton-loader-dashboard",
    templateUrl: "./dashboard-loader-card.component.html",
    styleUrls: ["./dashboard-loader-card.component.css"],
})

export class DashboardLoaderCardComponent  {
    @Input() isLoader:boolean = false;
    @Input() loaderFor:any;
    @Input() loaderType:any;
    @Input() largeCardFor:any;

}