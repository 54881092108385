import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampaignRedialRulesService {

  constructor() { }


  AfterTime= [
    { value: '300', value_name: '5 Minutes' }, 
    { value: '600', value_name: '10 Minutes' }, 
    { value: '900', value_name: '15 Minutes' }, 
    { value: '1800', value_name: '30 Minutes' }, 
    { value: '2700', value_name: '45 Minutes' }, 
    { value: '5400', value_name: '90 Minutes' }, 
    { value: '36000', value_name: '1 Hours' }, 
    { value: '72000', value_name: '2 Hours' }, 
    { value: '10800', value_name: '3 Hours' }, 
    { value: '14400', value_name: '4 Hours' }, 
    { value: '43200', value_name: '12 Hours' },
    { value: '86400', value_name: '1 Day' },
    { value: '172800', value_name: '2 Day' },
    { value: '259200', value_name: '3 Day' },
    { value: '345600', value_name: '4 Day' },
    { value: '432000', value_name: '5 Day' },
    { value: '518400', value_name: '6 Day' },
    { value: '604800', value_name: '1 Week' },
    { value: '1209600', value_name: '2 Week' },
    { value: '1814400', value_name: '3 Week' },

  ]
}
