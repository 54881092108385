import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'forget-password', component: ForgetPasswordComponent },
      { path: 'otp-verification', component: OtpVerificationComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: '', component: LoginComponent }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] ,
      data: { allowedRoles: ['Superuser','Admin','Tenant','Supervisor','Agent']},},
      { path: 'dashboard', component: ChangelogComponent, canActivate: [AuthGuard] ,
      data: { allowedRoles: ['Superuser','Admin','Tenant','Supervisor','Agent']},
    },
      {
        path: 'superadmin', loadChildren: () => import('../app/content/superadmin/superadmin.module').then(m => m.SuperadminModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Superuser','Admin']},
      },
      {
        path: 'agentandteam', loadChildren: () => import('../app/content/agentandteam/agentandteam.module').then(m => m.AgentandteamModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant']},
      },
      {
        path: 'tenant', loadChildren: () => import('../app/content/tenants/tenants.module').then(m => m.TenantsModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant','Supervisor']},
      },
      {
        path: 'queue', loadChildren: () => import('../app/content/queuemanager/queuemanager.module').then(m => m.QueuemanagerModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant']},
      },
      {
        path: 'campaigns', loadChildren: () => import('../app/content/campaignmanager/campaignmanager.module').then(m => m.CampaignmanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'security-manager', loadChildren: () => import('../app/content/securitymanager/securitymanager.module').then(m => m.SecuritymanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sms-email', loadChildren: () => import('../app/content/sms-email-manager/smsemailmanager.module').then(m => m.SmsemailmanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'voice-manager', loadChildren: () => import('../app/content/voice-manager/voicemanager.module').then(m => m.VoicemanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contact-management', loadChildren: () => import('../app/content/contactmanager/contactmanager.module').then(m => m.ContactmanagerModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant']},
      },
      {
        path: 'script-builder', loadChildren: () => import('../app/content/scriptmanager/scriptmanager.module').then(m => m.ScriptmanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'omnichannel', loadChildren: () => import('../app/content/omnichannel/omnichannel.module').then(m => m.OmnichannelModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reports-statistics', loadChildren: () => import('../app/content/reportsandstatistics/reportsandstatistics.module').then(m => m.ReportsandstatisticsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'qualitymanager', loadChildren: () => import('../app/content/qualitymanager/qualitymanager.module').then(m => m.QualitymanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'recordingsmanager', loadChildren: () => import('../app/content/recordingsmanager/recordingsmanager.module').then(m => m.RecordingsmanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'integrations', loadChildren: () => import('../app/content/integrationsmanager/integrationsmanager.module').then(m => m.IntegrationsmanagerModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant']}
      },
      {
        path: 'systemstatics', loadChildren: () => import('../app/content/systemstatistics/systemstatistics.module').then(m => m.SystemstatisticsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'advancedsettings', loadChildren: () => import('../app/content/advancedsettings/advancedsettings.module').then(m => m.AdvancedsettingsModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Superuser','Admin']}
      },
      {
        path: 'support', loadChildren: () => import('../app/content/superadmin/support/support.module').then(m => m.SupportModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Superuser','Admin']},
      },
      {
        path: 'supportmanager', loadChildren: () => import('../app/content/supportmanager/supportmanager.module').then(m => m.SupportmanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'agent', loadChildren: () => import('../app/content/agentsupervisor/agentsupervisor.module').then(m => m.AgentsupervisorModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Agent','Supervisor']},
      },
      {
        path: 'chats', loadChildren: () => import('../app/content/chat/chats.module').then(m => m.ChatsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile', loadChildren: () => import('../app/content/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant','Supervisor','Superuser','Admin','Agent']},
      },
   
      {
        path: 'tickets', loadChildren: () => import('../app/content/tickets/tickets.module').then(m => m.TicketsModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant','Supervisor','Agent','Admin']}
      },
      {
        path: 'flowFrame', loadChildren: () => import('../app/content/flow-frame/flow-frame.module').then(m => m.FlowFrameModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant']}
      },
      {
        path: 'sentiment', loadChildren: () => import('../app/content/sentiment/sentiment.module').then(m => m.SentimentModule),
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Tenant','Superuser']}
      }
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
