﻿import { Component, HostListener, Inject, OnInit, Injectable } from "@angular/core";
import { NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, NavigationCancel } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { AppConstants } from "./_helpers/app.constants";
import { MenuSettingsService } from "./_layout/settings/menu-settings.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Title } from "@angular/platform-browser";
import { AuthService } from "../app/_services/auth.service";
import { APIService } from "../app/_services/api.service";
import { ToastrService } from "ngx-toastr";
// it will triger from call event in index file
declare var customEvent: Subject<any>;
// declare var customStatusChange: Subject<any>;

@Component({
  selector: "app-main",
  templateUrl: "app.component.html",
})
@Injectable()
export class AppComponent implements OnInit {
  public _menuSettingsConfig: any;
  public _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  showContent = false;
  session_id:any;
  public title;
  dataOfUser: any;
  timeoutId;

  constructor(
    private spinner: NgxSpinnerService,
    public authService: AuthService,
    private apiService: APIService,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    public loader: LoadingBarService,
    private deviceService: DeviceDetectorService,
    public _menuSettingsService: MenuSettingsService,
    private titleService: Title
  ) {
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
    this.setTitle();
    customEvent = new Subject();
    // customStatusChange = new Subject();
  }
  ngOnInit() {
    this._menuSettingsService.config.pipe(takeUntil(this._unsubscribeAllMenu)).subscribe((config) => {
      this._menuSettingsConfig = config;
    });
    // page progress bar percentage
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
        this.showContent = true;
        // close menu for mobile view
        if (
          this.deviceService.isMobile() ||
          window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH
        ) {
          if (document.body.classList.contains("menu-open")) {
            document.body.classList.remove("menu-open");
            document.body.classList.add("menu-close");
          }
        }
      }
    });
    // event for call

    // customStatusChange.subscribe((data) => {});

    customEvent.subscribe(() => {
      if (this.router.url === "/agent/active-call") {
        this.router.navigateByUrl("/agent/call");
      } else if (this.router.url === "/agent/call") {
        this.router.navigateByUrl("/agent/active-call");
      } else {
        this.router.navigateByUrl("/agent/call");
      }
    });
    this.dataOfUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(() => {
      this.signout();
    }, 600000);
  }

  @HostListener("window:keydown")
  @HostListener("window:mousedown")
  checkUserActivity() {
    // clearTimeout(this.timeoutId);
    // this.checkTimeOut();
  }

  signout() {
    if (
      this.dataOfUser.account_type == "Agent" ||
      this.dataOfUser.account_type == "Supervisor"
    ) {
      // social set refresh start here
      const socialParam = {
        condition: "refresh",
        agent_uuid: this.dataOfUser.agent_domain_uuid
      }
      this.apiService.socialMediaLogout(socialParam).subscribe((response) => {
      });
      // social set refresh ends here  
      const requestDta = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        break_name: "Logged Out",
        domain_uuid: this.dataOfUser.domain_uuid,
        session_id: this.dataOfUser.session_id,
      };
      this.apiService.requestBreak(requestDta).subscribe((response) => {
        if (response["Status"] == 1) {
          this.authService.doLogout();
          // force logout start 
          const update_agent_status_data = {
            agent_uuid: this.dataOfUser.agent_domain_uuid,
            status: 'Logged Out'
          };
          this.apiService.forceLogout(update_agent_status_data).subscribe((response) => { });
          // force logout ends here 
          this.toastr.success(response["Msg"], "Success!", { progressBar: true });
        } else {
          this.toastr.error(response["Msg"], "Error!", { progressBar: true });
        }
      });
    } else if (
      this.dataOfUser.account_type == "Tenant" || this.dataOfUser.account_type == "Superuser" || this.dataOfUser.account_type == "Admin"
    ) {
      this.toastr.error("Session Expired!", "Error!", { progressBar: true });
      this.authService.doLogout();
    }
  }

  setTitle() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        for (
          let i = 0;
          i < this._menuSettingsConfig.vertical_menu.items.length;
          i++
        ) {
          if (
            !this._menuSettingsConfig.vertical_menu.items[i].submenu &&
            this._menuSettingsConfig.vertical_menu.items[i].page ===
            this.router.url
          ) {
            this.title = this._menuSettingsConfig.vertical_menu.items[i].title;
            break;
          } else if (this._menuSettingsConfig.vertical_menu.items[i].submenu) {
            // Level 1 menu
            for (
              let j = 0;
              j <
              this._menuSettingsConfig.vertical_menu.items[i].submenu.items
                .length;
              j++
            ) {
              if (
                !this._menuSettingsConfig.vertical_menu.items[i].submenu.items[
                  j
                ].submenu &&
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]
                  .page === this.router.url
              ) {
                this.title =
                  this._menuSettingsConfig.vertical_menu.items[i].submenu.items[
                    j
                  ].title;
                break;
              } else if (
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]
                  .submenu
              ) {
                // Level 2 menu
                for (
                  let k = 0;
                  k <
                  this._menuSettingsConfig.vertical_menu.items[i].submenu.items[
                    j
                  ].submenu.items.length;
                  k++
                ) {
                  if (
                    this._menuSettingsConfig.vertical_menu.items[i].submenu
                      .items[j].submenu.items[k].page === this.router.url
                  ) {
                    this.title =
                      this._menuSettingsConfig.vertical_menu.items[
                        i
                      ].submenu.items[j].submenu.items[k].title;
                  }
                }
              }
            }
          }
        }
        if (this.title && this.router.url !== "/") {
          this.titleService.setTitle(this.title + "- Telemo");
        } else {
          if (this.router.url === "/") {
            this.title = "Login | Telemo";
            this.titleService.setTitle(this.title + "- Telemo");
            this.title = "";
          } else if (this.router.url === "/login") {
            this.title = "Login | Telemo";
            this.titleService.setTitle(this.title + "- Telemo");
            this.title = "";
          } else if (this.router.url === "/register") {
            this.title = "Telemo";
            this.titleService.setTitle(this.title + "- Telemo");
            this.title = "";
          }
        }
      }
    });
  }
}
