
export interface MenuItem {

  dynamic_menu_item: any;
  title: string;
  icon: string;
  page: string;
  show: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {

  // super admin menu
  
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };

  // Admin Menus
  Admin_menu: {
    items: Partial<MenuItem>[]
  };
  // tenant menu
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
  TENANT_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
  AGENT_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
  SUPERVISOR_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
}

export const MenuSettingsConfig: MenuConfig = {

  horizontal_menu: {
    items: [
      //superadmin menu start
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Account Dashboard',
              page: '/dashboard',
              show: 'true',
            },
            {
              title: 'Live Dashboard',
              page: '/superadmin/live-dashboard',
              show: 'true',
            },
            {
              title: 'Wallboard',
              page: '/superadmin/wallboard',
              show: 'true',
            }
          ]
        }
      },
      {
        title: 'Accounts',
        icon: 'feather ft-users',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            // {
            //   title: 'Roles & Permissions',
            //   page: '/superadmin/roles-permissions'
            // },
            {
              title: 'Admin',
              page: '/superadmin/admins',
              show: 'true',
            },
            {
              title: 'Tenants',
              page: '/superadmin/tenant-list',
              show: 'true',
            },
            {
              title: 'Email SMTP',
              page: '/superadmin/email-smtp',
              show: 'true',
            },
            {
              title: 'Mail Templates',
              page: '/superadmin/email-notification-templates',
              show: 'true',
            },
            // {
            //   title: 'Email & Notifications',
            //   page: '/superadmin/email-notification-templates',
            // },
            {
              title: 'Sentiment Subscription',
              page: '/superadmin/sentiment-subscription-list',
              show: 'true',
            },
            {
              title: 'WhatsApp Bot Subscription',
              page: '/superadmin/whatsapp-bot-subscription',
              show: 'true',
            },
            {
              title: 'Telegram Bot Subscription',
              page: '/superadmin/telegram-bot-subscription',
              show: 'true',
            },
            {
              title: 'Web Chat Bot Subscription',
              page: '/superadmin/webchat-bot-subscription',
              show: 'true',
            },
            {
              title: 'Viber Bot Subscription',
              page: '/superadmin/viber-bot-subscription',
              show: 'true',
            },
            {
              title: 'Facebook Bot Subscription',
              page: '/superadmin/facebook-bot-subscription',
              show: 'true',
            },
            // {
            //   title: 'Video Chat Subscription',
            //   page: '/superadmin/video-chat-subscription',
            //   show: 'true',
            // },
            {
              title: 'Global Cron Jobs',
              page: '/superadmin/cron-jobs',
              show: 'true',
            },
          ]
        }
      },
      {
        title: 'Recordings',
        icon: 'la-headphones',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Recordings Manager',
              page: '/recordingsmanager/recordings',
              show: 'true',
            },

            {
              title: 'Recordings Backup',
              page: '/recordingsmanager/backup-manager',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'Reports & Statistics',
        icon: 'feather ft-activity',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Call Details Report',
              page: '/reports-statistics/call-details-report',
              show: 'true',
            },
            {
              title: 'Outbound Summary Report',
              page: '/reports-statistics/outbound-summary-report',
              show: 'true',
            },
            {
              title: 'Inbound Summary Report',
              page: '/reports-statistics/inbound-summary-report',
              show: 'true',
            },
            {
              title: 'Campaign Report',
              page: '/reports-statistics/campain-report',
              show: 'true',
            },
            {
              title: 'Agent Activity Report',
              page: '/reports-statistics/agent-activity-report',
              show: 'true',
            },
            // {
            //   title: 'Interactions History',
            //   page: '/recordingsmanager/under-dev'
            // },
            {
              title: 'Queue Report',
              page: '/reports-statistics/queue-report',
              show: 'true',
            },
            {
              title: ' Agent Disposition Report',
              page: '/reports-statistics/agent-disposition-report',
              show: 'true',
            },
            {
              title: ' Agent Summary Report Final',
              page: '/reports-statistics/agent-summary-report-final',
              show: 'true',

            },
            {
              title: 'Agent Performance Report',
              page: '/reports-statistics/agent-perfomance-report',
              show: 'true',
            },
            {
              title: 'Agent Sessions Report',
              page: '/reports-statistics/agent-session-report',
              show: 'true',
            },
            {
              title: 'DID Reporting',
              page: '/reports-statistics/did-reporting',
              show: 'true',

            },
            {
              title: 'Completed Call Backs Report',
              page: '/reports-statistics/completed-call-back-report',
              show: 'true',
            },
            {
              title: 'Scheduled Call Backs Report',
              page: '/reports-statistics/scheduled-call-back-report',
              show: 'true',
            },
            {
              title: ' Voice Broadcast Report',
              page: '/reports-statistics/voice-brodcast-report',
              show: 'true',
            },
            {
              title: ' SLA Abandoned Calls Report',
              page: '/reports-statistics/sla-abondoned-call-report',
              show: 'true',
            },
            {
              title: ' SLA Answered Calls Report',
              page: '/reports-statistics/sla-answered-call-reports',
              show: 'true',
            },
            {
              title: ' Interaction History Report',
              page: '/reports-statistics/interaction-history-report',
              show: 'true',
            },

            {
              title: 'SMS Report',
              page: '/reports-statistics/sms-report',
              show: 'true',
            },
            {
              title: 'IVR Report',
              page: '/reports-statistics/ivr-report',
              show: 'true',
            },
            {
              title: 'Custom Reports',
              page: '/reports-statistics/scheduled-report-list',
              show: 'true',
            },
            {
              title: 'Twitter Report',
              page: '/reports-statistics/twitter-report',
              show: 'true',
            },
            {
              title: 'Telegram Report',
              page: '/reports-statistics/telegram-report',
              show: 'true',
            },
            {
              title: 'Facebook Report',
              page: '/reports-statistics/facebook-report',
              show: 'true',
            },
            {
              title: 'Whatsapp Report',
              page: '/reports-statistics/whatsapp-report',
              show: 'true',
            },
            {
              title: 'WhatsApp Broadcast Report',
              page: '/reports-statistics/whatsapp-broadcast-report',
              show: 'true',
            },
            {
              title: 'Email Broadcast Report',
              page: '/reports-statistics/email-report',
              show: 'true',
            },
            {
              title: 'Email Campaign Report',
              page: '/reports-statistics/inbound-email-report',
              show: 'true',
            },
            {
              title: 'Social Disposition Report',
              page: '/reports-statistics/Social-disposition-report',
              show: 'true',
            },
            {
              title: 'Web Chat Report',
              page: '/reports-statistics/web-chat-report',
              show: 'true',
            },
            {
              title: 'Ticket Report',
              page: '/tickets/all-tickets',
              show: 'true',
            },
            {
              title: 'Script Report',
              page: '/reports-statistics/script-report',
              show: 'true',
            },
            {
              title: 'Internal Chat Report',
              page: '/reports-statistics/internal-chat-report',
              show: 'true',
            },
            {
              title: 'Viber Report',
              page: '/reports-statistics/viber-report',
              show: 'true',
            },
            {
              title: 'Order Report',
              page: '/reports-statistics/order-report',
              show: 'true',
            },
          ]
        },
      },
      // {
      //   title: 'System Statistics',
      //   icon: 'feather ft-monitor',
      //   page: 'null',
      //   show: 'true',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'System Statistics',
      //         page: '/systemstatics/system-statistic',
      //         show: 'true',
      //       },
      //       {
      //         title: 'System Status',
      //         page: '/systemstatics/system-status',
      //         show: 'true',
      //       },
      //       {
      //         title: 'Registered SIP Devices',
      //         page: '/systemstatics/registered-sip-devices',
      //         show: 'true',
      //       },
      //       {
      //         title: 'Gateways',
      //         page: '/systemstatics/gateway',
      //         show: 'true',
      //       },
      //     ]
      //   },
      // },
      // {
      //   title: 'Advanced Settings',
      //   icon: 'feather ft-settings',
      //   page: 'null',
      //   show: 'true',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Nodes',
      //         page: '/advancedsettings/under-dev',
      //         show: 'true',
      //       },
      //       {
      //         title: 'Proxy Nodes/ Container',
      //         page: '/advancedsettings/proxy-nodes',
      //         show: 'true',
      //       },
      //       {
      //         title: 'Media and Media relay Nodes / containers',
      //         page: '/advancedsettings/under-dev',
      //         show: 'true',
      //       },
      //       {
      //         title: 'Databases Management',
      //         page: '/advancedsettings/manage-database',
      //         show: 'true',
      //       },
      //       {
      //         title: 'Access Log',
      //         page: '/advancedsettings/access-log',
      //         show: 'true',
      //       },
      //     ]
      //   },
      // },
      // {
      //   title: 'Security',
      //   icon: 'feather ft-lock',
      //   page: 'null',
      //   show: 'true',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Fail2ban',
      //         page: '/superadmin/security',
      //         show: 'true',
      //       }
      //     ]
      //   }
      // },
      {
        title: 'Support',
        icon: 'la la-support',
        page: '/support/open-support-ticket',
        show: 'true',
      },
      // {
      //   title: 'Profile Setting',
      //   icon: 'icon-user',
      //   page: '/profile',
      // },
    ]
  },
  Admin_menu: {
    items: [
      //superadmin menu start
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Account Dashboard',
              page: '/dashboard',
              show: 'true',
            },
            {
              title: 'Live Dashboard',
              page: '/superadmin/live-dashboard',
              show: 'true',
            },
            {
              title: 'Wallboard',
              page: '/superadmin/wallboard',
              show: 'true',
            }
          ]
        }
      },
      {
        title: 'Accounts',
        icon: 'feather ft-users',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            // {
            //   title: 'Roles & Permissions',
            //   page: '/superadmin/roles-permissions'
            // },
            // {
            //   title: 'Admin',
            //   page: '/superadmin/admins',
            //   show: 'true',
            // },
            {
              title: 'Tenants',
              page: '/superadmin/tenant-list',
              show: 'true',
            },
            {
              title: 'Email SMTP',
              page: '/superadmin/email-smtp',
              show: 'true',
            },
            {
              title: 'Mail Templates',
              page: '/superadmin/email-notification-templates',
              show: 'true',
            },
            // {
            //   title: 'Email & Notifications',
            //   page: '/superadmin/email-notification-templates',
            // },
            {
              title: 'Global Cron Jobs',
              page: '/superadmin/cron-jobs',
              show: 'true',
            },
          ]
        }
      },
      {
        title: 'Recordings',
        icon: 'la-headphones',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Recordings Manager',
              page: '/recordingsmanager/recordings',
              show: 'true',
            },

            {
              title: 'Recordings Backup',
              page: '/recordingsmanager/backup-manager',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'Reports & Statistics',
        icon: 'feather ft-activity',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Call Details Report',
              page: '/reports-statistics/call-details-report',
              show: 'true',
            },
            {
              title: 'Outbound Summary Report',
              page: '/reports-statistics/outbound-summary-report',
              show: 'true',
            },
            {
              title: 'Inbound Summary Report',
              page: '/reports-statistics/inbound-summary-report',
              show: 'true',
            },
            {
              title: 'User Productivity Report',
              page: '/reports-statistics/channel-utilization-report',
              show: 'true',
            },
            
            {
              title: 'Campaign Report',
              page: '/reports-statistics/campain-report',
              show: 'true',
            },
            // {
            //   title: 'Interactions History',
            //   page: '/recordingsmanager/under-dev'
            // },
            {
              title: 'Queue Report',
              page: '/reports-statistics/queue-report',
              show: 'true',
            },
            {
              title: ' Agent Disposition Report',
              page: '/reports-statistics/agent-disposition-report',
              show: 'true',
            },
            {
              title: ' Agent Summary Report Final',
              page: '/reports-statistics/agent-summary-report-final',
              show: 'true',

            },
            {
              title: 'Agent Performance Report',
              page: '/reports-statistics/agent-perfomance-report',
              show: 'true',
            },
            {
              title: 'Agent Sessions Report',
              page: '/reports-statistics/agent-session-report',
              show: 'false',
            },
            {
              title: 'DID Reporting',
              page: '/reports-statistics/did-reporting',
              show: 'true',

            },
            {
              title: 'Completed Call Backs Report',
              page: '/reports-statistics/completed-call-back-report',
              show: 'true',
            },
            {
              title: 'Scheduled Call Backs Report',
              page: '/reports-statistics/scheduled-call-back-report',
              show: 'true',
            },
            {
              title: ' Voice Broadcast Report',
              page: '/reports-statistics/voice-brodcast-report',
              show: 'true',
            },
            {
              title: ' SLA Abandoned Calls Report',
              page: '/reports-statistics/sla-abondoned-call-report',
              show: 'true',
            },
            {
              title: ' SLA Answered Calls Report',
              page: '/reports-statistics/sla-answered-call-reports',
              show: 'true',
            },
            {
              title: ' Interaction History Report',
              page: '/reports-statistics/interaction-history-report',
              show: 'true',
            },

            {
              title: 'SMS Report',
              page: '/reports-statistics/sms-report',
              show: 'true',
            },
            {
              title: 'IVR Report',
              page: '/reports-statistics/ivr-report',
              show: 'true',
            },
            // {
            //   title: 'Custom Reports',
            //   page: '/reports-statistics/scheduled-report-list',
            //   show: 'true',
            // },
            // {
            //   title: 'Twitter Report',
            //   page: '/reports-statistics/twitter-report',
            //   show: 'true',
            // },
            {
              title: 'Facebook Report',
              page: '/reports-statistics/facebook-report',
              show: 'true',
            },
            {
              title: 'Whatsapp Report',
              page: '/reports-statistics/whatsapp-report',
              show: 'true',
            },
            {
              title: 'Email Broadcast Report',
              page: '/reports-statistics/email-report',
              show: 'true',
            },
            {
              title: 'Email Campaign Report',
              page: '/reports-statistics/inbound-email-report',
              show: 'true',
            },
            {
              title: 'Social Disposition Report',
              page: '/reports-statistics/Social-disposition-report',
              show: 'true',
            },
            // {
            //   title: 'Web Chat Report',
            //   page: '/reports-statistics/web-chat-report',
            //   show: 'true',
            // }
          ]
        },
      },
      {
        title: 'System Statistics',
        icon: 'feather ft-monitor',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'System Statistics',
              page: '/systemstatics/system-statistic',
              show: 'true',
            },
            {
              title: 'System Status',
              page: '/systemstatics/system-status',
              show: 'true',
            },
            {
              title: 'Registered SIP Devices',
              page: '/systemstatics/registered-sip-devices',
              show: 'true',
            },
            {
              title: 'Gateways',
              page: '/systemstatics/gateway',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'Advanced Settings',
        icon: 'feather ft-settings',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Nodes',
              page: '/advancedsettings/under-dev',
              show: 'true',
            },
            {
              title: 'Proxy Nodes/ Container',
              page: '/advancedsettings/proxy-nodes',
              show: 'true',
            },
            {
              title: 'Media and Media relay Nodes / containers',
              page: '/advancedsettings/under-dev',
              show: 'true',
            },
            {
              title: 'Databases Management',
              page: '/advancedsettings/manage-database',
              show: 'true',
            },
            {
              title: 'Access Log',
              page: '/advancedsettings/access-log',
              show: 'true',
            },
          ]
        },
      },
      // {
      //   title: 'Security',
      //   icon: 'feather ft-lock',
      //   page: 'null',
      //   show: 'true',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Fail2ban',
      //         page: '/superadmin/security',
      //         show: 'true',
      //       }
      //     ]
      //   }
      // },
      {
        title: 'Support',
        icon: 'la la-support',
        page: '/support/open-support-ticket',
        show: 'true',
      },
      // {
      //   title: 'Profile Setting',
      //   icon: 'icon-user',
      //   page: '/profile',
      // },
    ]
  },
  vertical_menu: {
    items: [
      // // tenant menu start
      // { section: 'Tenant', icon: 'la-ellipsis-h' },
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Account Dashboard',
              icon: 'la-home',
              page: '/dashboard',
              show: 'true',
            },
            {
              title: 'Live Dashboard',
              icon: 'icon-bar-chart',
              page: '/tenant/live-dashboard',
              show: 'true',
            },
            {
              title: 'Wallboard',
              icon: 'icon-paper-plane',
              page: '/tenant/wallboard',
              show: 'true',
            },
          ]
        }
      },
      {
        title: 'Sentiment',
        icon: 'la-expand',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Sentiment Dashboard',
              icon: 'la-home',
              page: 'sentiment/sentiment-dashboard',
              show: 'true',
            },
          ]
        }
      },
      {
        title: 'Agents & Teams',
        icon: 'icon-users',
        page: '/agentandteam',
        show: 'true',
      },
      {
        title: 'VOICE',
        icon: 'icon-volume-2',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Trunk',
              page: '/voice-manager/trunks',
              show: 'true',
            },
            {
              title: 'DID',
              page: '/voice-manager/dids',
              show: 'true',
            },
            {
              title: 'Music On Hold',
              page: '/voice-manager/mohs',
              show: 'true',
            },
            {
              title: 'Prompts & Announcements',
              page: '/voice-manager/prompts',
              show: 'true',
            },
            // {
            //   title: 'CLI Manager',
            //   page: '/voice-manager/cli-manager'
            // },
            // {
            //   title: 'Routing Options',
            //   page: '/voice-manager/under-dev'
            // },
            // {
            //   title: 'FAX',
            //   page: '/voice-manager/fax-manager'
            // },
            {
              title: 'Conferences',
              page: '/voice-manager/conference-list',
              show: 'true',
            },
            {
              title: 'Priority Numbers',
              page: '/voice-manager/high-priority-numbers',
              show: 'true',
            },
            {
              title: 'Time Conditions',
              page: '/voice-manager/time-conditions',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'SMS & EMAIL',
        icon: 'la-envelope',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'SMS Gateways',
              page: '/sms-email/apis-list',
              show: 'true',
            },
            {
              title: 'Mail SMTP',
              page: '/sms-email/smtp-config',
              show: 'true',
            },
            {
              title: 'Mail Templates',
              page: '/sms-email/mail-templates',
              show: 'true',
            }
          ]
        },
      },
      {
        title: 'Queues',
        icon: 'feather ft-layers',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'IVR',
              page: '/queue/ivr-list',
              show: 'true',
            },
            {
              title: 'Call Queue',
              page: '/queue/queue-list',
              show: 'true',
            },
            {
              title: 'SMS Queue',
              page: '/queue/sms-queue',
              show: 'true',
            },
            {
              title: 'Email Queue',
              page: '/queue/email-queue',
              show: 'true',
            },
            // {
            //   title: 'Chat Queue',
            //   page: '/queue/chat-queue',
            //   show: 'true',
            // },
            {
              title: 'Social Queue',
              page: '/queue/social-queues',
              show: 'true',
            },
            {
              title: 'Open Ticket',
              page: '/queue/open-ticket-list',
              show: 'true',
            },
            {
              title: 'Closed Ticket',
              page: '/queue/closed-ticket-list',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'Campaigns',
        icon: 'la-bullhorn',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Outbound Campaign',
              page: '/campaigns/outbound-campaign-list',
              show: 'true',
            },
            {
              title: 'Inbound Campaign',
              page: '/campaigns/inbound-campaign-list',
              show: 'true',
            },
            {
              title: 'Blended Campaign',
              page: '/campaigns/blended-campaign-list',
              show: 'true',
            },
            {
              title: 'Dispositions',
              page: '/campaigns/dispositions',
              show: 'true',
            },
            {
              title: 'Scheduled Callback',
              page: '/campaigns/scheduled-callbacks',
              show: 'true',
            },
            {
              title: 'Completed Callback',
              page: '/campaigns/completed-callbacks',
              show: 'true',
            },
            {
              title: 'Typification',
              page: '/campaigns/typification',
              show: 'true',
            },
            {
              title: 'Voice Broadcast',
              page: '/campaigns/voice-broadcasts',
              show: 'true',
            },
            {
              title: 'WhatsApp Template',
              page: '/campaigns/whatsapp-templates',
              show: 'true',
            },
            {
              title: 'WhatsApp Broadcast',
              page: '/campaigns/whatsapp-broadcasts',
              show: 'true',
            },
            {
              title: 'Web Surveys',
              page: '/campaigns/web-surveys',
              show: 'true',
            },
            {
              title: 'SMS Campaign',
              page: '/campaigns/sms-campaigns',
              show: 'true',
            },
            {
              title: 'Email Campaigns',
              page: '/campaigns/mail-campaigns',
              show: 'true',
            },
            {
              title: 'Email Broadcast',
              page: '/campaigns/email-broadcast',
              show: 'true',
            },
            {
              title: 'Social Campaigns',
              page: '/campaigns/social-campaign-list',
              show: 'true',
            }
          ]
        },
      },
      {
        title: 'Security',
        icon: 'feather ft-lock',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'DNC List',
              page: '/security-manager/dnc-list',
              show: 'true',
            },
            {
              title: 'Black List',
              page: '/security-manager/blacklist',
              show: 'true',
            },
            // {
            //   title: 'Fraud detection',
            //   page: '/security-manager/fraud-detection'
            // }
          ]
        },
      },
      {
        title: 'Contact Management',
        icon: 'la-book',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'CRM',
              icon: 'grid-outline',
              page: '/contact-management/list',
              show: 'true',
            },
            {
              title: 'Contact List',
              page: '/contact-management/contact-list',
              show: 'true',
            },
            // {
            //   title: 'Contact Management',
            //   page: '/contact-management/contact-management'
            // },
            // {
            //   title: 'Filters',
            //   page: '/contact-management/filter',
            //   show: 'true',
            // },
          ]
        },
      },
      {
        title: 'SCRIPT',
        page: '/script-builder',
        icon: 'la-stack-exchange',
        show: 'true',
      },
      {
        title: 'Flow',
        icon: 'feather ft-repeat',
        page: '/flowFrame/flow',
        show: 'true',
      },
      {
        title: 'Voicemail',
        icon: 'feather ft-voicemail',
        page: '/agent/voicemails',
        show: 'true',
      },
      {
        title: 'Omnichannel',
        icon: 'icon-social-facebook',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'WhatsApp',
              page: '/omnichannel/whatsapp-configuration-list',
              show: 'true',
            },
            {
              title: 'WhatsApp Bot',
              page: '/omnichannel/whatsapp-configuration-list',
              show:'true',
            },
            {
              title: 'Facebook',
              page: '/omnichannel/facebook-configuration-list',
              show: 'true',
            },
            {
              title: 'Facebook Accounts',
              page: '/omnichannel/facebook-post-account',
              show: 'true',
            },
            {
              title: 'Twitter',
              page: '/omnichannel/twitter-congfigs',
              show: 'false',
            },
            {
              title: 'Twitter Accounts',
              page: '/omnichannel/manage-twitter-post-accounts',
             show: 'false',
            },
            {
              title: 'Telegram',
              page: '/omnichannel/telegram-configs',
              show: 'true',
            },
            {
              title: 'Telegram Bot',
              page: '/omnichannel/telegram-bot',
              show: 'true',
            },
            {
              title: 'Web Chat',
              page: '/omnichannel/webchat-configuration',
              show: 'true',
            },
            {
              title: 'Web Chat Bot',
              page: '/omnichannel/webchat-bot',
              show: 'true',
            },
            {
              title: 'Viber',
              page: '/omnichannel/webchat-configuration',
              show: 'true',
            },
            {
              title: 'viber Bot',
              page: '/omnichannel/webchat-bot',
              show: 'true',
            },
       
          ]
        },
      },
      {
        title: 'Reports & Statistics',
        icon: 'feather ft-activity',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Call Details Report',
              page: '/reports-statistics/call-details-report',
              show: 'true',
            },
            {
              title: 'Outbound Summary Report',
              page: '/reports-statistics/outbound-summary-report',
              show: 'true',
            },
            {
              title: 'Inbound Summary Report',
              page: '/reports-statistics/inbound-summary-report',
              show: 'true',
            },
            {
              title: 'Campaign Report',
              page: '/reports-statistics/campain-report',
              show: 'true',
            },
            // {
            //   title: 'Interactions History',
            //   page: '/recordingsmanager/under-dev'
            // },
            {
              title: 'Queue Report',
              page: '/reports-statistics/queue-report',
              show: 'true',
            },
            {
              title: 'Agent Activity Report',
              page: '/reports-statistics/agent-activity-report',
              show: 'true',
            },
            {
              title: 'Agent Disposition Report',
              page: '/reports-statistics/agent-disposition-report',
              show: 'true',
            },
            {
              title: 'Agent Summary Report Final',
              page: '/reports-statistics/agent-summary-report-final',
              show: 'true',
            },
            {
              title: 'Agent Performance Report',
              page: '/reports-statistics/agent-perfomance-report',
              show: 'true',
            },
            {
              title: 'Agent Sessions Report',
              page: '/reports-statistics/agent-session-report',
              show: 'false',
            },
            {
              title: 'DID Reporting',
              page: '/reports-statistics/did-reporting',
              show: 'true',

            },
            {
              title: 'Completed Call Backs Report',
              page: '/reports-statistics/completed-call-back-report',
              show: 'true',
            },
            {
              title: 'Scheduled Call Backs Report',
              page: '/reports-statistics/scheduled-call-back-report',
              show: 'true',
            },
            {
              title: 'Voice Broadcast Report',
              page: '/reports-statistics/voice-brodcast-report',
              show: 'true',
            },
            {
              title: 'SLA Abandoned Calls Report',
              page: '/reports-statistics/sla-abondoned-call-report',
              show: 'true',
            },
            {
              title: 'SLA Answered Calls Report',
              page: '/reports-statistics/sla-answered-call-reports',
              show: 'true',
            },
            {
              title: 'Interaction History Report',
              page: '/reports-statistics/interaction-history-report',
              show: 'true',
            },

            {
              title: 'SMS Report',
              page: '/reports-statistics/sms-report',
              show: 'true',
            },
            {
              title: 'IVR Report',
              page: '/reports-statistics/ivr-report',
              show: 'true',
            },
            {
              title: 'Custom Reports',
              page: '/reports-statistics/scheduled-report-list',
              show: 'true',
            },
            // {
            //   title: 'Twitter Report',
            //   page: '/reports-statistics/twitter-report',
            //   show: 'true',
            // },
            {
              title: 'Facebook Report',
              page: '/reports-statistics/facebook-report',
              show: 'true',
            },
            {
              title: 'Whatsapp Report',
              page: '/reports-statistics/whatsapp-report',
              show: 'true',
            },
            {
              title: 'WhatsApp Broadcast Report',
              page: '/reports-statistics/whatsapp-broadcast-report',
              show: 'true',
            },
            {
              title: 'Email Broadcast Report',
              page: '/reports-statistics/email-report',
              show: 'true',
            },
            {
              title: 'Email Campaign Report',
              page: '/reports-statistics/inbound-email-report',
              show: 'true',
            },
            {
              title: 'Social Disposition Report',
              page: '/reports-statistics/Social-disposition-report',
              show: 'true',
            },
            {
              title: 'Web Chat Report',
              page: '/reports-statistics/web-chat-report',
              show: 'true',
            }
          ]
        },
      },
      {
        title: 'Recordings',
        icon: 'la-headphones',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Recordings Manager',
              page: '/recordingsmanager/recordings',
              show: 'true',
            },
            {
              title: 'Recordings Backup',
              page: '/recordingsmanager/backup-manager',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'Quality Management',
        icon: 'la-balance-scale',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Agent Performance Evaluation',
              page: '/qualitymanager/agent-performance',
              show: 'true',
            },
            {
              title: 'Call Quality Evaluation',
              page: '/qualitymanager/call-quality-evaluation'
            },
            {
              title: 'From Template',
              page: '/integrations/under-dev',
              show: 'true',
            },
            {
              title: 'Call Quality Evaluation',
              page: '/qualitymanager/call-evaluation-form',
              show: 'true',
            },
            // {
            //   title: 'Customer Satisfaction',
            //   page: '/qualitymanager/customer-satisfaction'
            // },
            {
              title: 'Survey Template',
              page: '/qualitymanager/from-template',
              show: 'false'
            },
            {
              title: 'Customer Survey',
              page: '/integrations/under-dev',
              show: 'false',
            },

          ]
        },
      },
      // {
      //   title: 'BOT',
      //   icon: 'la-reddit-square',
      //   page: 'null',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'BOT Setting',
      //         page: '/botmanager/bot-setting'
      //       },
      //       // {
      //       //   title: 'Preview',
      //       //   page: '/botmanager/bot-preview'
      //       // },
      //     ]
      //   },
      // },
      {
        title: 'Integrations',
        icon: 'feather ft-cpu',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Click-to-Call',
              page: '/integrations/click-to-call',
              show: 'true',
            },
            {
              title: 'API',
              page: '/integrations/apis',
              show: 'true',
            },
            {
              title: 'Salesforce',
              page: '/integrations/sales-force',
              show: 'true'
            },
            {
              title: 'Zoho',
              page: '/integrations/zoho',
              show: 'true',
            },
            {
              title: 'Odoo',
              page: '/integrations/odoo',
              show: 'true',
            },
            {
              title: 'Zendesk',
              page: '/integrations/zendesk',
              show: 'true',
            },
            {
              title: 'Bitrix24',
              page: '/integrations/bitrix',
              show: 'true',
            },
            {
              title: '3rd Party SQL Database Integration',
              page: '/integrations/sql-database',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'Support',
        icon: 'la la-support',
        page: '/supportmanager/manage',
        show: 'true',
      },
      // {
      //   title: 'Support',
      //   icon: 'la-support',
      //   page: 'null',
      //   show: 'true',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Support',
      //         page: '/supportmanager/manage',
      //         show: 'true',
      //       },
      //       {
      //         title: 'Documentation',
      //         page: '/supportmanager/under-dev',
      //         show: 'true'
      //       },
      //     ]
      //   },
      // },
      //      {
      //   title: 'Profile Setting',
      //   icon: 'icon-user',
      //   page: '/profile',
      // },
    ]
  },
  TENANT_MENU_ITEMS: {
    items: []
  },
   // AGENT_MENU_ITEMS: JSON.parse(sessionStorage.getItem('superAdminData')),
   AGENT_MENU_ITEMS: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard',
        show: 'true',
      },
      {
        title: 'Call',
        icon: 'la-phone',
        page: '/agent/call',
        show: 'true',
      },
      {
        title: 'Voicemail',
        icon: 'feather ft-voicemail',
        page: '/agent/voicemails',
        show: 'true',
      },
      {
        title: 'Callbacks',
        icon: 'feather ft-phone-outgoing',
        page: '/agent/callbacks',
        show: 'true',
      },
      {
        title: 'Parked Calls',
        icon: 'feather ft-phone-call',
        page: '/agent/parked-call',
        show: 'true',
      },
      {
        title: 'Chat',
        icon: 'la-comments',
        page: '/chats/static-chat',
        show: 'true',
      },
      {
        title: 'Web-chat',
        icon: 'la-comments',
        page: '/chats/web-chat',
        show:'true',
      },
      {
        title: 'SMS',
        icon: 'feather ft-message-square',
        page: '/agent/sms-agent',
        show: 'false',
      },
      {
        title: 'Facebook Wall',
        icon: 'icon-social-facebook',
        page: '/agent/fb-wall',
        show: 'true',
      },
      {
        title: 'Facebook',
        icon: 'icon-social-facebook',
        page: '/agent/facebook',
        show: 'true',
      },
      {
        title: 'Whatsapp',
        icon: 'la-whatsapp',
        page: '/agent/whatsapp',
        show: 'true',
      },

      {
        title: 'Quality Feedback',
        icon: 'la-balance-scale',
        page: '/agent/quality-feedback',
        show: 'true',
      },
      {
        title: 'Tickets',
        icon: 'la-balance-scale',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'All Tickets',
              page: '/agent/all-agent-tickets',
              show: 'true',
            },
            {
              title: 'Pending Tickets',
              page: '/agent/pending-tickets',
              show: 'true',
            },
            {
              title: 'Due Tickets',
              page: '/agent/due-tickets',
              show: 'true',
            },
            {
              title: 'My Tickets',
              page: '/agent/my-tickets',
              show: 'true',
            },
            {
              title: 'Unresponded Tickets',
              page: '/agent/unresponded-tickets',
              show: 'true',
            },
          ]
        },
      },
    ]
  },
  // SUPERVISOR_MENU_ITEMS: JSON.parse(sessionStorage.getItem('superAdminData'))
  SUPERVISOR_MENU_ITEMS: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Dashboard',
              icon: 'la-home',
              page: '/dashboard',
        show: 'true',

            },
            {
              title: 'Live Dashboard',
              icon: 'icon-bar-chart',
              page: '/tenant/live-dashboard',
        show: 'false',

            },
            {
              title: 'Wallboard',
              icon: 'icon-paper-plane',
              page: '/tenant/wallboard',
        show: 'false',

            }
          ]
        }
      },
      {
        title: 'Call',
        icon: 'la-phone',
        page: '/agent/call',
        show: 'true'
      },
      {
        title: 'Tickets',
        icon: 'la-ticket',
        page: '/agent/tickets'
      },
      {
        title: 'Voicemail',
        icon: 'feather ft-voicemail',
        page: '/agent/voicemails',
        show: 'true'
      },
      {
        title: 'Callbacks',
        icon: 'feather ft-phone-outgoing',
        page: '/agent/callbacks',
        show: 'true',
      },
      {
        title: 'Parked Calls',
        icon: 'feather ft-phone-call',
        page: '/agent/parked-call',
        show: 'true',
      },
      {
        title: 'Chat',
        icon: 'la-comments',
        page: '/chats/static-chat',
        show: 'true'
      },
      {
        title: 'Web-chat',
        icon: 'la-comments',
        page: '/chats/web-chat',
        show:'true',
      },
      {
        title: 'SMS',
        icon: 'feather ft-message-square',
        page: '/agent/sms-agent',
        show: 'false'
      },
      {
        title: 'Manage Team',
        icon: 'la-users',
        page: '/agent/manage-team',
        show: 'true'
      },
      // {
      //   title: 'Active Calls',
      //   icon: 'la-line-chart',
      //   page: '/agent/live-calls'
      // },
      {
        title: 'Facebook Wall',
        icon: 'icon-social-facebook',
        page: '/agent/fb-wall',
        show: 'true'
      },
      {
        title: 'Facebook',
        icon: 'icon-social-facebook',
        page: '/agent/facebook',
        show: 'true'
      },
      {
        title: 'Whatsapp',
        icon: 'la-whatsapp',
        page: '/agent/whatsapp',
        show: 'true'
      },
      // {
      //   title: 'Facebook',
      //   icon: 'icon-social-facebook',
      //   page: '/agent/facebook',
      // },
      // {
      //   title: 'Twitter',
      //   icon: 'feather ft-twitter',
      //   page: '/agent/twitter',
      // },
      {
        title: 'Recordings',
        icon: 'la-headphones',
        page: '/recordingsmanager/recordings',
        show: 'true'
      },
      {
        title: 'Reports & Statistics',
        icon: 'feather ft-activity',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Call Details Report',
              page: '/reports-statistics/call-details-report',
              show: 'true',
            },
            {
              title: 'Outbound Summary Report',
              page: '/reports-statistics/outbound-summary-report',
              show: 'true',
            },
            {
              title: 'Inbound Summary Report',
              page: '/reports-statistics/inbound-summary-report',
              show: 'true',
            },
            {
              title: 'Campaign Report',
              page: '/reports-statistics/campain-report',
              show: 'true',
            },
            // {
            //   title: 'Interactions History',
            //   page: '/recordingsmanager/under-dev'
            // },
            {
              title: 'Queue Report',
              page: '/reports-statistics/queue-report',
              show: 'true',
            },
            {
              title: ' Agent Disposition Report',
              page: '/reports-statistics/agent-disposition-report',
              show: 'true',
            },
            {
              title: ' Agent Summary Report Final',
              page: '/reports-statistics/agent-summary-report-final',
              show: 'true',

            },
            {
              title: 'Agent Performance Report',
              page: '/reports-statistics/agent-perfomance-report',
              show: 'true',
            },
            {
              title: 'Agent Sessions Report',
              page: '/reports-statistics/agent-session-report',
              show: 'false',
            },
            {
              title: 'DID Reporting',
              page: '/reports-statistics/did-reporting',
              show: 'true',

            },
            // {
            //   title: 'Completed Call Backs Report',
            //   page: '/reports-statistics/completed-call-back-report',
            //   show: 'true',
            // },
            // {
            //   title: 'Scheduled Call Backs Report',
            //   page: '/reports-statistics/scheduled-call-back-report',
            //   show: 'true',
            // },
            {
              title: ' Voice Broadcast Report',
              page: '/reports-statistics/voice-brodcast-report',
              show: 'true',
            },
            {
              title: ' SLA Abandoned Calls Report',
              page: '/reports-statistics/sla-abondoned-call-report',
              show: 'true',
            },
            {
              title: ' SLA Answered Calls Report',
              page: '/reports-statistics/sla-answered-call-reports',
              show: 'true',
            },
            {
              title: ' Interaction History Report',
              page: '/reports-statistics/interaction-history-report',
              show: 'false',
            },

            // {
            //   title: 'SMS Report',
            //   page: '/reports-statistics/sms-report',
            //   show: 'true',
            // },
            {
              title: 'IVR Report',
              page: '/reports-statistics/ivr-report',
              show: 'true',
            },
            // {
            //   title: 'Custom Reports',
            //   page: '/reports-statistics/scheduled-report-list',
            //   show: 'true',
            // },
            // {
            //   title: 'Twitter Report',
            //   page: '/reports-statistics/twitter-report',
            //   show: 'true',
            // },
            {
              title: 'Telegram Report',
              page: '/reports-statistics/telegram-report',
              show: "true",
            },
            {
              title: 'Facebook Report',
              page: '/reports-statistics/facebook-report',
              show: 'true',
            },
            {
              title: 'WhatsApp Report',
              page: '/reports-statistics/whatsapp-report',
              show: 'true',
            },
            {
              title: 'WhatsApp Broadcast Report',
              page: '/reports-statistics/whatsapp-broadcast-report',
              show: 'true',
            },
            {
              title: 'Email Broadcast Report',
              page: '/reports-statistics/email-report',
              show: 'true',
            },
            {
              title: 'Email Campaign Report',
              page: '/reports-statistics/inbound-email-report',
              show: 'true',
            },
            
            // {
            //   title: 'Email Report',
            //   page: '/reports-statistics/email-report',
            //   show: 'true',
            // },
            {
              title: 'Social Disposition Report',
              page: '/reports-statistics/Social-disposition-report',
              show: 'true',
            },
              {
              title: 'Web Chat Report',
              page: '/reports-statistics/web-chat-report',
              show: 'true',
            },
            {
              title: 'Ticket Report',
              page: '/tickets/all-tickets',
              show: 'true',
            },
            {
              title: 'Script Report',
              page:'/omnichannel/script-report',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'Quality Feedback',
        icon: 'la-balance-scale',
        page: '/agent/quality-feedback',
        show: 'true'
      },
      {
        title: 'Tickets',
        icon: 'la-balance-scale',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'All Tickets',
              page: '/agent/all-agent-tickets',
              show: 'true',
            },
            {
              title: 'Pending Tickets',
              page: '/agent/pending-tickets',
              show: 'true',
            },
            {
              title: 'Due Tickets',
              page: '/agent/due-tickets',
              show: 'true',
            },
            {
              title: 'My Tickets',
              page: '/agent/my-tickets',
              show: 'true',
            },
            {
              title: 'Unresponded Tickets',
              page: '/agent/unresponded-tickets',
              show: 'true',
            },
          ]
        },
      },

      // {
      //   title: 'Agent Report',
      //   icon: 'feather ft-activity',
      //   page: 'null',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Summary Report',
      //         page: '/agent/summary-report'
      //       },
      //       {
      //         title: 'CDR',
      //         page: '/agent/agent-cdr'
      //       },
      //     ]
      //   }
      // }
      // {
      //   title: 'Profile Setting',
      //   icon: 'icon-user',
      //   page: '/profile',
      // },
    ]
  }
};

