export const environment = {
    production: false,
    PROJECT_NAME:'Telemo',
    copyright_company_name:'startelelogic',
    copyrightURL:'https://www.startelelogic.com/',
    TELEPHONY_URL:'https://media.telemo.io',
    API_URL: 'https://mycc.telemo.io:10707/',
    AGENT_QUEUE_URL: 'https://mycc.telemo.io:20707/',
    SOCIAL_MEDIA_URL: 'https://mycc.telemo.io:30707/',
    TWITTER_BASE_URL: 'https://mycc.telemo.io:40707/',
    FACEBOOK_BASE_URL: 'https://mycc.telemo.io:30707/',
    WHATSAPP_BASE_URL: 'https://mycc.telemo.io:60707/',
    VOICE_BASE_URL: 'https://mycc.telemo.io:30101/',
    TELEGRAM_BASE_URL: 'https://mycc.telemo.io:8443/',
    VIBER_BASE_URL: 'https://mycc.telemo.io:30303/',
    DOMAIN_URL: 'https://mycc.telemo.io',
    HTTP_DOMAIN_URL:"http://mycc.telemo.io",
    ProxyUrl: 'proxy.telemo.io',
    ProxyPort:'7443',
    wsEndpoint: 'wss://proxy.telemo.io',
    SocketIOChatUrl: 'https://mycc.telemo.io:9000/',
    AgentSessionSocketUrl:'wss://mycc.telemo.io:9889',
    RECORDING_URL:'https://media.telemo.io',
    RecordingtelephonyURl:'https://media.telemo.io' ,
    LOG: (e) => console.log(e),
    ERROR: (e) => console.error(e),
    firebase: {
      apiKey: 'AIzaSyAvHtu5rrqKSZQg75R2MYqlNgGdMKy2YR4',
      projectId: 'modern-admin-8453e',
      authDomain: 'modern-admin-8453e.firebaseapp.com',
      appId: '1:300550201463:web:96a271cc309ac5aea86672'
    },
    googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU'
};