import { Component, OnInit } from '@angular/core';
import { DarkModeService } from 'angular-dark-mode';
import { Observable } from 'rxjs';
import { ThemeSettingsService } from './../_layout/settings/theme-settings.service';

@Component({
  selector: 'app-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  styleUrls: ['./dark-mode-toggle.component.css']
})
export class DarkModeToggleComponent implements OnInit {
  darkMode$: Observable<boolean> = this.darkModeService.darkMode$;

  constructor(private darkModeService: DarkModeService, private _themeSettingsService: ThemeSettingsService) {}

  ngOnInit(): void {
    this.darkModeService.disable();
  }
  

  onToggle(e): void {
    this.darkModeService.toggle();
    if(e.target.checked) {
    this._themeSettingsService.config = {
      menuColor: 'menu-dark', // light-menu, dark-menu
    };
   } else {
      this._themeSettingsService.config = {
        menuColor: 'menu-light', // light-menu, dark-menu
   }
  }
}

}
