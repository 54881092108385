import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() { }

  /**
   * Create a unique uuid version 6
   */
  get create_uuid(): string {
    let dt: number = new Date().getTime();
    let uuid: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r: number = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }
  basic_time_duration(totalTime){
    const duration = new Date(totalTime * 1000).toISOString().slice(11, 19);
    return duration;
  }
  /**
   * Find the short name using given name
   * @param { string } name - Name from which short name to extract
   * @returns { string } - Extracted shortname from name
   */
  short_name_finder(name: string): string {
    return name
      .split(/\s+/)
      .slice(0, 2)
      .map((data) => data[0])
      .join("")
      .toUpperCase();
  }

  /**
   * Calculate time in 12 hour and 24 hour format
   * @param { number } epoch_time - Milliseconds from midnight of januray 1, 1970.
   * @param { string } time_format - Valid values are 12_hour and 24_hour
   * @returns { string} Return time according to passed time format
   */
  get_time(epoch_time: number, time_format: string): string {
    const date: Date = new Date(epoch_time);
    const divider: number = time_format === "12_hour" ? 12 : 24;
    let hour_remainder: number | string = date.getHours() % divider;
    let minute_remainder: number | string = date.getMinutes() % 60;
    const AM_or_PM: string = date.getHours() >= 12 ? "PM" : "AM";

    if (hour_remainder < 10 && hour_remainder == 0 && time_format == "12_hour") {
      hour_remainder = 12;
    } else if (hour_remainder < 10 && hour_remainder == 0 && time_format == "24_hour") {
      hour_remainder = "00";
    } else if (hour_remainder < 10 && hour_remainder != 0) {
      hour_remainder = `0${hour_remainder}`;
    }

    if (minute_remainder < 10) minute_remainder = `0${minute_remainder}`;

    return `${hour_remainder}:${minute_remainder}${time_format == "12_hour" ? ` ${AM_or_PM}` : ""
      }`;
  }

  remove_quotes(data: string) {
    data = data.replace(/'/g, "&apos;");
    return data.replace(/"/g, "&quot;");
  }

  /**
   * Set cursor position at end of text
   * @param { HTMLDivElement } target - target element to which set cursor at end of text
   */
  set_cursor_to_end(target: HTMLDivElement) {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(target);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
    range.detach();
    target.scrollTop = target.scrollHeight;
  }

  /**
   * Scroll the specified container to bottom
   * @param element{string} - ID of element to scroll to bottom
   * @returns {void}
   */
  scroll_to_bottom(element: string): void {
    let container: HTMLElement = document.getElementById(element);
    if (container)
      container.scroll({
        top: container.scrollHeight + 100,
        left: 0,
        behavior: "smooth",
      });
  }

  human_readable_time_duration(total_time: number): string {
    const total_day: number = Math.floor(total_time / (24 * 60 * 60));
    total_time -= 24 * 60 * 60 * total_day;
    const total_hour: number = Math.floor(total_time / 3600);
    total_time -= 3600 * total_hour;
    const total_minute: number = Math.floor(total_time / 60);
    const total_second: number = total_time - 60 * total_minute;

    let duration = "";
    if (total_day) duration += `${total_day}d`;

    if (!total_day && total_hour) duration += `${total_hour}h`;
    else if (total_day && total_hour) duration += ` ${total_hour}h`;

    if (!total_day && !total_hour && total_minute) duration += `${total_minute}m`;
    else if ((total_day || total_hour) && total_minute) duration += ` ${total_minute}m`;

    if (!total_day && !total_hour && !total_minute && total_second)
      duration += `${total_second}s`;
    else if ((total_day || total_hour || total_minute) && total_second)
      duration += ` ${total_second}s`;
    else if (!total_day && !total_hour && !total_minute && !total_second)
      duration += ` ${total_second}s`;

    return duration;
  }

  /**
   * Calculate file size in B, KB, MB
   * @param { number } size - file size in bytes
   * @returns { string } return file size in B, KB, MB
   */
  calculate_file_size(size: number): string {
    let sizes: Array<string> = ["B", "KB", "MB"];
    let readable_size: string = `0 ${sizes[0]}`;

    for (let i = 2; i >= 0; --i) {
      if (size >= Math.pow(1000, i) && size < Math.pow(1000, i + 1)) {
        readable_size = `${size / Math.pow(1000, i)} ${sizes[i]}`;
        break;
      }
    }
    return readable_size;
  }
}
