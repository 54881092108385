import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, switchMap, tap } from 'rxjs/operators';
import { TokenStorageService } from '../../../../src/app/_services/token-storage.service';
import { APIService } from '../../_services/api.service';
import { AuthService } from "../../../app/_services/auth.service";
import { Router } from '@angular/router';
@Injectable()

export class AuthInterceptorService implements HttpInterceptor {
  authToken: any; // Replace with your actual bearer token
  refreshToken: any; // Replace with your actual refresh token
  private isRefreshing = false;
  private refreshSubject: Observable<any> | null = null;
  dataOfUser: any;
  refreshTokenParams: { refreshToken: any; domain_uuid: any; agent_uuid: string; userType: string; };
  query_param_domain_id: any;
  loginData: any;

  constructor(
    public tokenStorage: TokenStorageService,
    public apiService: APIService,
    public authService: AuthService,
    public Router: Router
  ) {
    this.dataOfUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request and add the Authorization header with the bearer token
    if (request.url.endsWith('/login/') || request.url.endsWith('/forgot_password') || request.url.endsWith('/change_password')
      || request.url.endsWith('/verify_otp') || request.url.endsWith('/update_password/') || request.url.endsWith('/resend_otp')
      || request.url.endsWith('/refreshtoken') || request.url.endsWith('/requestBreak') || request.url.endsWith('/get-notifications-count')
      || request.url.endsWith('/get-call-park-details-by-domain-uuid-by-get-method') || request.url.endsWith('/update-whatsapp-message-notification') 
      || request.url.endsWith('//get-scheduled-callbacks') || request.url.endsWith('/update-facebook-message-notification')
      || request.url.endsWith('/update-webchat-message-notification') || request.url.endsWith('/update-telegram-message-notification')
      || request.url.endsWith('/update-viber-message-notification') || request.url.endsWith('/update-internalchat-message-notification')
      || request.url.endsWith('/get-available-agent-list')) {
      // Skip adding headers and query parameters for the login API
      return next.handle(request);
    } else {
      this.loginData = this.tokenStorage.getAccountType()
      if (this.tokenStorage.getAuthToken()) {
        var authRequest = this.addTokenToRequest(request, this.tokenStorage.getAuthToken());
        var updatedRequest = this.addQueryParamsToRequest(authRequest, this.queryParamsForDifferentLogin());
      }

      // Continue with the cloned request
      return next.handle(updatedRequest).pipe(
        catchError((error: HttpErrorResponse) => {

          if (error instanceof HttpErrorResponse && error.status == 401) {
            // Token expired, initiate token refresh
            if (!this.isRefreshing) {
              this.isRefreshing = true;
              if (this.loginData.account_type == 'Agent' || this.loginData.account_type == 'Supervisor') {
                this.refreshTokenParams = {
                  refreshToken: this.tokenStorage.getRefreshToken(),
                  domain_uuid: this.loginData.domain_uuid,
                  agent_uuid: this.loginData.agent_domain_uuid,
                  userType: "Agent"
                }
              } else if (this.loginData.account_type == 'Superuser' || this.loginData.account_type == 'Admin') {
                this.refreshTokenParams = {
                  refreshToken: this.tokenStorage.getRefreshToken(),
                  domain_uuid: this.loginData.domain_uuid,
                  agent_uuid: '',
                  userType: "Superadmin"
                }
              } else if (this.loginData.account_type == 'Tenant') {
                this.refreshTokenParams = {
                  refreshToken: this.tokenStorage.getRefreshToken(),
                  agent_uuid: '',
                  domain_uuid: this.loginData.domain_uuid,
                  userType: "Tenant"
                }
              }
              this.refreshSubject = this.apiService.refreshToken(this.refreshTokenParams).pipe(
                switchMap((response) => {
                  if (response['Status'] == 1) {
                    // Refresh successful, update the bearer token
                    this.authToken = response['Auth_token'];
                    this.tokenStorage.setAuthToken(response['Auth_token']);
                    if (response['Auth_token']) {
                      var authRequest = this.addTokenToRequest(request, this.tokenStorage.getAuthToken());
                      var updatedRequest = this.addQueryParamsToRequest(authRequest, this.queryParamsForDifferentLogin());
                      return next.handle(updatedRequest).pipe(
                        retry(1),
                        catchError((error: HttpErrorResponse) => {
                          if (error.status == 401) {
                            // const data = this.addTokenToRequest(request, this.tokenStorage.getAuthToken());
                            // return next.handle(data).pipe(retry(2));
                          }
                          // Handle error, log it, or do something else if needed
                          return throwError(error);
                        })
                      );
                    }

                  } else {
                    // this.authService.doLogout();
                    return of(undefined);
                  }
                }),
                catchError((refreshError) => {
                  // Perform logout
                  // this.authService.doLogout();
                  return of(undefined); // Return an observable with undefined
                }),
                tap(() => {
                  this.isRefreshing = false;
                  this.refreshSubject = null;
                })
              );
            }

            return this.refreshSubject || throwError('Token refresh failed');
          } else {
            // Handle other errors
            return throwError(error);
          }
        })
      );
    }

  }

  private addQueryParamsToRequest(
    request: HttpRequest<any>,
    queryParams: { [key: string]: string }
  ): HttpRequest<any> {
    let updatedRequest = request;

    const params = new URLSearchParams();
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        params.set(key, queryParams[key]);
      }
    }

    const updatedUrl = `${request.url}?${params.toString()}`;
    updatedRequest = updatedRequest.clone({
      url: updatedUrl,
    });

    return updatedRequest;
  }


  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  queryParamsForDifferentLogin() {
    if(this.loginData && this.loginData.account_type) {
    if (this.loginData.account_type === 'Supervisor' || this.loginData.account_type === 'Agent') {
      this.query_param_domain_id = this.loginData.agent_domain_uuid
    } else {
      this.query_param_domain_id = this.loginData.domain_uuid;
    }
    var queryParams = {
      user_uuid: this.query_param_domain_id,
    };
    return queryParams;
    } else {
      this.Router.navigateByUrl("/login");
    }

  }


}
