import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  socket: any;
  readonly uri: string = environment.SocketIOChatUrl;

  constructor() {
    this.socket = io(this.uri);
    console.log("loglog", this.socket);
  }

  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  emit(eventName: string, data: any) {
    console.log(eventName, data);
    this.socket.emit(eventName, data);
  }


  stopListening(...agrs: Array<String>) {
    agrs.length != 0
      ? this.socket.removeAllListeners(agrs)
      : this.socket.removeAllListeners();
  }
}
