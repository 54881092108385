import { Injectable } from "@angular/core";
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from "firebase/app";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  public dialerStatusForCrm: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(public afAuth: AngularFireAuth) { }

  doAnonymousLogin() {
    return this.afAuth.auth.signInAnonymously();
  }
  nextSubjectForCrm(value){
    this.dialerStatusForCrm.next(value);
    }
  doLogout() {
    sessionStorage.clear();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("superuser_user");
    window.location.href = "/login";
  }
}
