import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  // constructor() { }
  timeZones = [
    { timeZone: 'Iceland' },
    { timeZone: 'Jamaica' },
    { timeZone: 'Zulu' },
    { timeZone: 'GB-Eire' },
    { timeZone: 'GB' },
    { timeZone: 'EST' },
    { timeZone: 'Eire' },
    { timeZone: 'CST6CDT' },
    { timeZone: 'Poland' },
    { timeZone: 'Factory' },
    { timeZone: 'Libya' },
    { timeZone: 'Africa/Ceuta' },
    { timeZone: 'Africa/Niamey' },
    { timeZone: 'Africa/Timbuktu' },
    { timeZone: 'Africa/Kigali' },
    { timeZone: 'Africa/Blantyre' },
    { timeZone: 'Africa/Windhoek' },
    { timeZone: 'Africa/Johannesburg' },
    { timeZone: 'Africa/Kampala' },
    { timeZone: 'Africa/Lagos' },
    { timeZone: 'Africa/Monrovia' },
    { timeZone: 'Africa/Asmara' },
    { timeZone: 'Africa/Freetown' },
    { timeZone: 'Africa/Cairo' },
    { timeZone: 'Africa/Nouakchott' },
    { timeZone: 'Africa/Dakar' },
    { timeZone: 'Africa/Bissau' },
    { timeZone: 'Africa/Maseru' },
    { timeZone: 'Africa/Dar_es_Salaam' },
    { timeZone: 'Africa/Asmera' },
    { timeZone: 'Africa/Malabo' },
    { timeZone: 'Africa/Addis_Ababa' },
    { timeZone: 'Africa/Banjul' },
    { timeZone: 'Africa/Sao_Tome' },
    { timeZone: 'Africa/Bujumbura' },
    { timeZone: 'Africa/Porto-Novo' },
    { timeZone: 'Africa/Mbabane' },
    { timeZone: 'Africa/Tripoli' },
    { timeZone: 'Africa/Ouagadougou' },
    { timeZone: 'Africa/Djibouti' },
    { timeZone: 'Africa/Gaborone' },
    { timeZone: 'Africa/Tunis' },
    { timeZone: 'Africa/Mogadishu' },
    { timeZone: 'Africa/Abidjan' },
    { timeZone: 'Africa/El_Aaiun' },
    { timeZone: 'Africa/Luanda' },
    { timeZone: 'Africa/Lome' },
    { timeZone: 'Africa/Accra' },
    { timeZone: 'Africa/Conakry' },
    { timeZone: 'Africa/Bamako' },
    { timeZone: 'Africa/Algiers' },
    { timeZone: 'Africa/Lusaka' },
    { timeZone: 'Africa/Nairobi' },
    { timeZone: 'Africa/Douala' },
    { timeZone: 'Africa/Bangui' },
    { timeZone: 'Africa/Khartoum' },
    { timeZone: 'Africa/Libreville' },
    { timeZone: 'Africa/Maputo' },
    { timeZone: 'Africa/Harare' },
    { timeZone: 'Africa/Brazzaville' },
    { timeZone: 'Africa/Casablanca' },
    { timeZone: 'Africa/Juba' },
    { timeZone: 'Africa/Ndjamena' },
    { timeZone: 'Africa/Lubumbashi' },
    { timeZone: 'Africa/Kinshasa' },
    { timeZone: 'Antarctica/Vostok' },
    { timeZone: 'Antarctica/Macquarie' },
    { timeZone: 'Antarctica/Troll' },
    { timeZone: 'Antarctica/South_Pole' },
    { timeZone: 'Antarctica/Casey' },
    { timeZone: 'Antarctica/Davis' },
    { timeZone: 'Antarctica/McMurdo' },
    { timeZone: 'Antarctica/DumontDUrville' },
    { timeZone: 'Antarctica/Rothera' },
    { timeZone: 'Antarctica/Mawson' },
    { timeZone: 'Antarctica/Palmer' },
    { timeZone: 'Antarctica/Syowa' },
    { timeZone: 'Hongkong' },
    { timeZone: 'Turkey' },
    { timeZone: 'GMT0' },
    { timeZone: 'ROK' },
    { timeZone: 'WET' },
    { timeZone: 'Brazil/East' },
    { timeZone: 'Brazil/West' },
    { timeZone: 'Brazil/DeNoronha' },
    { timeZone: 'Brazil/Acre' },
    { timeZone: 'Universal' },
    { timeZone: 'EET' },
    { timeZone: 'EST5EDT' },
    { timeZone: 'Iran' },
    { timeZone: 'Arctic/Longyearbyen' },
    { timeZone: 'PRC' },
    { timeZone: 'Europe/Monaco' },
    { timeZone: 'Europe/San_Marino' },
    { timeZone: 'Europe/Kirov' },
    { timeZone: 'Europe/Uzhgorod' },
    { timeZone: 'Europe/Copenhagen' },
    { timeZone: 'Europe/Gibraltar' },
    { timeZone: 'Europe/Skopje' },
    { timeZone: 'Europe/Sofia' },
    { timeZone: 'Europe/Madrid' },
    { timeZone: 'Europe/Brussels' },
    { timeZone: 'Europe/Zagreb' },
    { timeZone: 'Europe/Mariehamn' },
    { timeZone: 'Europe/Riga' },
    { timeZone: 'Europe/Ulyanovsk' },
    { timeZone: 'Europe/Minsk' },
    { timeZone: 'Europe/Tiraspol' },
    { timeZone: 'Europe/Bucharest' },
    { timeZone: 'Europe/Moscow' },
    { timeZone: 'Europe/Saratov' },
    { timeZone: 'Europe/Tirane' },
    { timeZone: 'Europe/Vatican' },
    { timeZone: 'Europe/London' },
    { timeZone: 'Europe/Guernsey' },
    { timeZone: 'Europe/Andorra' },
    { timeZone: 'Europe/Nicosia' },
    { timeZone: 'Europe/Vilnius' },
    { timeZone: 'Europe/Tallinn' },
    { timeZone: 'Europe/Jersey' },
    { timeZone: 'Europe/Bratislava' },
    { timeZone: 'Europe/Belgrade' },
    { timeZone: 'Europe/Kiev' },
    { timeZone: 'Europe/Zaporozhye' },
    { timeZone: 'Europe/Belfast' },
    { timeZone: 'Europe/Astrakhan' },
    { timeZone: 'Europe/Vaduz' },
    { timeZone: 'Europe/Podgorica' },
    { timeZone: 'Europe/Samara' },
    { timeZone: 'Europe/Ljubljana' },
    { timeZone: 'Europe/Malta' },
    { timeZone: 'Europe/Lisbon' },
    { timeZone: 'Europe/Zurich' },
    { timeZone: 'Europe/Berlin' },
    { timeZone: 'Europe/Vienna' },
    { timeZone: 'Europe/Sarajevo' },
    { timeZone: 'Europe/Simferopol' },
    { timeZone: 'Europe/Athens' },
    { timeZone: 'Europe/Volgograd' },
    { timeZone: 'Europe/Rome' },
    { timeZone: 'Europe/Dublin' },
    { timeZone: 'Europe/Amsterdam' },
    { timeZone: 'Europe/Kaliningrad' },
    { timeZone: 'Europe/Oslo' },
    { timeZone: 'Europe/Prague' },
    { timeZone: 'Europe/Isle_of_Man' },
    { timeZone: 'Europe/Budapest' },
    { timeZone: 'Europe/Helsinki' },
    { timeZone: 'Europe/Paris' },
    { timeZone: 'Europe/Luxembourg' },
    { timeZone: 'Europe/Chisinau' },
    { timeZone: 'Europe/Busingen' },
    { timeZone: 'Europe/Stockholm' },
    { timeZone: 'Europe/Istanbul' },
    { timeZone: 'Europe/Warsaw' },
    { timeZone: 'localtime' },
    { timeZone: 'Australia/Victoria' },
    { timeZone: 'Australia/Lindeman' },
    { timeZone: 'Australia/Hobart' },
    { timeZone: 'Australia/NSW' },
    { timeZone: 'Australia/ACT' },
    { timeZone: 'Australia/Currie' },
    { timeZone: 'Australia/Tasmania' },
    { timeZone: 'Australia/Broken_Hill' },
    { timeZone: 'Australia/Adelaide' },
    { timeZone: 'Australia/West' },
    { timeZone: 'Australia/Darwin' },
    { timeZone: 'Australia/LHI' },
    { timeZone: 'Australia/Queensland' },
    { timeZone: 'Australia/Brisbane' },
    { timeZone: 'Australia/North' },
    { timeZone: 'Australia/Perth' },
    { timeZone: 'Australia/Yancowinna' },
    { timeZone: 'Australia/Canberra' },
    { timeZone: 'Australia/Lord_Howe' },
    { timeZone: 'Australia/Sydney' },
    { timeZone: 'Australia/Eucla' },
    { timeZone: 'Australia/Melbourne' },
    { timeZone: 'Australia/South' },
    { timeZone: 'GMT-0' },
    { timeZone: 'NZ-CHAT' },
    { timeZone: 'CET' },
    { timeZone: 'MET' },
    { timeZone: 'Navajo' },
    { timeZone: 'Portugal' },
    { timeZone: 'MST' },
    { timeZone: 'Canada/Eastern' },
    { timeZone: 'Canada/Mountain' },
    { timeZone: 'Canada/Saskatchewan' },
    { timeZone: 'Canada/Yukon' },
    { timeZone: 'Canada/Pacific' },
    { timeZone: 'Canada/Central' },
    { timeZone: 'Canada/Newfoundland' },
    { timeZone: 'Canada/Atlantic' },
    { timeZone: 'Greenwich' },
    { timeZone: 'GMT+0' },
    { timeZone: 'Asia/Harbin' },
    { timeZone: 'Asia/Muscat' },
    { timeZone: 'Asia/Atyrau' },
    { timeZone: 'Asia/Chita' },
    { timeZone: 'Asia/Thimbu' },
    { timeZone: 'Asia/Omsk' },
    { timeZone: 'Asia/Manila' },
    { timeZone: 'Asia/Famagusta' },
    { timeZone: 'Asia/Ashkhabad' },
    { timeZone: 'Asia/Baku' },
    { timeZone: 'Asia/Aden' },
    { timeZone: 'Asia/Tomsk' },
    { timeZone: 'Asia/Bahrain' },
    { timeZone: 'Asia/Qostanay' },
    { timeZone: 'Asia/Tel_Aviv' },
    { timeZone: 'Asia/Beirut' },
    { timeZone: 'Asia/Kabul' },
    { timeZone: 'Asia/Kolkata' },
    { timeZone: 'Asia/Seoul' },
    { timeZone: 'Asia/Anadyr' },
    { timeZone: 'Asia/Kathmandu' },
    { timeZone: 'Asia/Macao' },
    { timeZone: 'Asia/Vladivostok' },
    { timeZone: 'Asia/Tbilisi' },
    { timeZone: 'Asia/Barnaul' },
    { timeZone: 'Asia/Saigon' },
    { timeZone: 'Asia/Aqtobe' },
    { timeZone: 'Asia/Tehran' },
    { timeZone: 'Asia/Urumqi' },
    { timeZone: 'Asia/Samarkand' },
    { timeZone: 'Asia/Qatar' },
    { timeZone: 'Asia/Ust-Nera' },
    { timeZone: 'Asia/Aqtau' },
    { timeZone: 'Asia/Macau' },
    { timeZone: 'Asia/Amman' },
    { timeZone: 'Asia/Thimphu' },
    { timeZone: 'Asia/Colombo' },
    { timeZone: 'Asia/Vientiane' },
    { timeZone: 'Asia/Chungking' },
    { timeZone: 'Asia/Dili' },
    { timeZone: 'Asia/Nicosia' },
    { timeZone: 'Asia/Ulaanbaatar' },
    { timeZone: 'Asia/Qyzylorda' },
    { timeZone: 'Asia/Gaza' },
    { timeZone: 'Asia/Riyadh' },
    { timeZone: 'Asia/Phnom_Penh' },
    { timeZone: 'Asia/Hovd' },
    { timeZone: 'Asia/Khandyga' },
    { timeZone: 'Asia/Jayapura' },
    { timeZone: 'Asia/Choibalsan' },
    { timeZone: 'Asia/Makassar' },
    { timeZone: 'Asia/Ujung_Pandang' },
    { timeZone: 'Asia/Ulan_Bator' },
    { timeZone: 'Asia/Kuwait' },
    { timeZone: 'Asia/Yangon' },
    { timeZone: 'Asia/Jakarta' },
    { timeZone: 'Asia/Karachi' },
    { timeZone: 'Asia/Chongqing' },
    { timeZone: 'Asia/Hong_Kong' },
    { timeZone: 'Asia/Novosibirsk' },
    { timeZone: 'Asia/Bangkok' },
    { timeZone: 'Asia/Kamchatka' },
    { timeZone: 'Asia/Dhaka' },
    { timeZone: 'Asia/Rangoon' },
    { timeZone: 'Asia/Yekaterinburg' },
    { timeZone: 'Asia/Dubai' },
    { timeZone: 'Asia/Oral' },
    { timeZone: 'Asia/Dushanbe' },
    { timeZone: 'Asia/Yerevan' },
    { timeZone: 'Asia/Irkutsk' },
    { timeZone: 'Asia/Pyongyang' },
    { timeZone: 'Asia/Hebron' },
    { timeZone: 'Asia/Bishkek' },
    { timeZone: 'Asia/Almaty' },
    { timeZone: 'Asia/Kuala_Lumpur' },
    { timeZone: 'Asia/Tashkent' },
    { timeZone: 'Asia/Tokyo' },
    { timeZone: 'Asia/Taipei' },
    { timeZone: 'Asia/Ashgabat' },
    { timeZone: 'Asia/Novokuznetsk' },
    { timeZone: 'Asia/Magadan' },
    { timeZone: 'Asia/Jerusalem' },
    { timeZone: 'Asia/Shanghai' },
    { timeZone: 'Asia/Katmandu' },
    { timeZone: 'Asia/Kashgar' },
    { timeZone: 'Asia/Brunei' },
    { timeZone: 'Asia/Dacca' },
    { timeZone: 'Asia/Kuching' },
    { timeZone: 'Asia/Sakhalin' },
    { timeZone: 'Asia/Srednekolymsk' },
    { timeZone: 'Asia/Ho_Chi_Minh' },
    { timeZone: 'Asia/Krasnoyarsk' },
    { timeZone: 'Asia/Singapore' },
    { timeZone: 'Asia/Pontianak' },
    { timeZone: 'Asia/Damascus' },
    { timeZone: 'Asia/Yakutsk' },
    { timeZone: 'Asia/Baghdad' },
    { timeZone: 'Asia/Istanbul' },
    { timeZone: 'NZ' },
    { timeZone: 'PST8PDT' },
    { timeZone: 'Israel' },
    { timeZone: 'Chile/EasterIsland' },
    { timeZone: 'Chile/Continental' },
    { timeZone: 'Cuba' },
    { timeZone: 'MST7MDT' },
    { timeZone: 'GMT' },
    { timeZone: 'W-SU' },
    { timeZone: 'Kwajalein' },
    { timeZone: 'Japan' },
    { timeZone: 'SystemV/HST10' },
    { timeZone: 'SystemV/AST4' },
    { timeZone: 'SystemV/CST6CDT' },
    { timeZone: 'SystemV/EST5EDT' },
    { timeZone: 'SystemV/CST6' },
    { timeZone: 'SystemV/MST7' },
    { timeZone: 'SystemV/YST9' },
    { timeZone: 'SystemV/PST8PDT' },
    { timeZone: 'SystemV/MST7MDT' },
    { timeZone: 'SystemV/EST5' },
    { timeZone: 'SystemV/PST8' },
    { timeZone: 'SystemV/AST4ADT' },
    { timeZone: 'SystemV/YST9YDT' },
    { timeZone: 'America/Resolute' },
    { timeZone: 'America/Lima' },
    { timeZone: 'America/Santarem' },
    { timeZone: 'America/Anguilla' },
    { timeZone: 'America/Whitehorse' },
    { timeZone: 'America/Mazatlan' },
    { timeZone: 'America/Jamaica' },
    { timeZone: 'America/St_Vincent' },
    { timeZone: 'America/Cuiaba' },
    { timeZone: 'America/Campo_Grande' },
    { timeZone: 'America/Araguaina' },
    { timeZone: 'America/St_Thomas' },
    { timeZone: 'America/Ojinaga' },
    { timeZone: 'America/Knox_IN' },
    { timeZone: 'America/Kentucky/Louisville' },
    { timeZone: 'America/Kentucky/Monticello' },
    { timeZone: 'America/Creston' },
    { timeZone: 'America/Cambridge_Bay' },
    { timeZone: 'America/Anchorage' },
    { timeZone: 'America/Marigot' },
    { timeZone: 'America/Montserrat' },
    { timeZone: 'America/Noronha' },
    { timeZone: 'America/Louisville' },
    { timeZone: 'America/Martinique' },
    { timeZone: 'America/Rosario' },
    { timeZone: 'America/Porto_Velho' },
    { timeZone: 'America/Toronto' },
    { timeZone: 'America/Yellowknife' },
    { timeZone: 'America/El_Salvador' },
    { timeZone: 'America/Tegucigalpa' },
    { timeZone: 'America/Inuvik' },
    { timeZone: 'America/Nassau' },
    { timeZone: 'America/Boa_Vista' },
    { timeZone: 'America/Tijuana' },
    { timeZone: 'America/Sitka' },
    { timeZone: 'America/Caracas' },
    { timeZone: 'America/Guadeloupe' },
    { timeZone: 'America/Swift_Current' },
    { timeZone: 'America/New_York' },
    { timeZone: 'America/Winnipeg' },
    { timeZone: 'America/Santiago' },
    { timeZone: 'America/Montreal' },
    { timeZone: 'America/Denver' },
    { timeZone: 'America/Miquelon' },
    { timeZone: 'America/Bahia_Banderas' },
    { timeZone: 'America/Detroit' },
    { timeZone: 'America/Buenos_Aires' },
    { timeZone: 'America/Atka' },
    { timeZone: 'America/Indiana/Knox' },
    { timeZone: 'America/Indiana/Petersburg' },
    { timeZone: 'America/Indiana/Winamac' },
    { timeZone: 'America/Indiana/Vevay' },
    { timeZone: 'America/Indiana/Vincennes' },
    { timeZone: 'America/Indiana/Marengo' },
    { timeZone: 'America/Indiana/Indianapolis' },
    { timeZone: 'America/Indiana/Tell_City' },
    { timeZone: 'America/Guatemala' },
    { timeZone: 'America/Guayaquil' },
    { timeZone: 'America/Scoresbysund' },
    { timeZone: 'America/Rankin_Inlet' },
    { timeZone: 'America/Thunder_Bay' },
    { timeZone: 'America/Belem' },
    { timeZone: 'America/Punta_Arenas' },
    { timeZone: 'America/Sao_Paulo' },
    { timeZone: 'America/Atikokan' },
    { timeZone: 'America/Fort_Nelson' },
    { timeZone: 'America/Barbados' },
    { timeZone: 'America/St_Barthelemy' },
    { timeZone: 'America/Montevideo' },
    { timeZone: 'America/Eirunepe' },
    { timeZone: 'America/Bogota' },
    { timeZone: 'America/Danmarkshavn' },
    { timeZone: 'America/Jujuy' },
    { timeZone: 'America/St_Kitts' },
    { timeZone: 'America/Monterrey' },
    { timeZone: 'America/Regina' },
    { timeZone: 'America/Merida' },
    { timeZone: 'America/Porto_Acre' },
    { timeZone: 'America/Santa_Isabel' },
    { timeZone: 'America/Nipigon' },
    { timeZone: 'America/Maceio' },
    { timeZone: 'America/Cayenne' },
    { timeZone: 'America/Coral_Harbour' },
    { timeZone: 'America/Argentina/ComodRivadavia' },
    { timeZone: 'America/Argentina/Salta' },
    { timeZone: 'America/Argentina/Buenos_Aires' },
    { timeZone: 'America/Argentina/Jujuy' },
    { timeZone: 'America/Argentina/Rio_Gallegos' },
    { timeZone: 'America/Argentina/Mendoza' },
    { timeZone: 'America/Argentina/Ushuaia' },
    { timeZone: 'America/Argentina/San_Luis' },
    { timeZone: 'America/Argentina/Cordoba' },
    { timeZone: 'America/Argentina/San_Juan' },
    { timeZone: 'America/Argentina/Tucuman' },
    { timeZone: 'America/Argentina/Catamarca' },
    { timeZone: 'America/Argentina/La_Rioja' },
    { timeZone: 'America/Mendoza' },
    { timeZone: 'America/Santo_Domingo' },
    { timeZone: 'America/Guyana' },
    { timeZone: 'America/Managua' },
    { timeZone: 'America/Rio_Branco' },
    { timeZone: 'America/Grand_Turk' },
    { timeZone: 'America/Fortaleza' },
    { timeZone: 'America/Rainy_River' },
    { timeZone: 'America/Paramaribo' },
    { timeZone: 'America/Havana' },
    { timeZone: 'America/Godthab' },
    { timeZone: 'America/Virgin' },
    { timeZone: 'America/Kralendijk' },
    { timeZone: 'America/Iqaluit' },
    { timeZone: 'America/Ensenada' },
    { timeZone: 'America/Manaus' },
    { timeZone: 'America/Nome' },
    { timeZone: 'America/Pangnirtung' },
    { timeZone: 'America/Cordoba' },
    { timeZone: 'America/Goose_Bay' },
    { timeZone: 'America/Glace_Bay' },
    { timeZone: 'America/Nuuk' },
    { timeZone: 'America/Adak' },
    { timeZone: 'America/Costa_Rica' },
    { timeZone: 'America/Juneau' },
    { timeZone: 'America/Dawson_Creek' },
    { timeZone: 'America/Asuncion' },
    { timeZone: 'America/Hermosillo' },
    { timeZone: 'America/Thule' },
    { timeZone: 'America/Los_Angeles' },
    { timeZone: 'America/Bahia' },
    { timeZone: 'America/Moncton' },
    { timeZone: 'America/Halifax' },
    { timeZone: 'America/Cancun' },
    { timeZone: 'America/Chihuahua' },
    { timeZone: 'America/Boise' },
    { timeZone: 'America/Lower_Princes' },
    { timeZone: 'America/Curacao' },
    { timeZone: 'America/La_Paz' },
    { timeZone: 'America/Blanc-Sablon' },
    { timeZone: 'America/Grenada' },
    { timeZone: 'America/Antigua' },
    { timeZone: 'America/St_Lucia' },
    { timeZone: 'America/Cayman' },
    { timeZone: 'America/Dawson' },
    { timeZone: 'America/North_Dakota/Beulah' },
    { timeZone: 'America/North_Dakota/Center' },
    { timeZone: 'America/North_Dakota/New_Salem' },
    { timeZone: 'America/Metlakatla' },
    { timeZone: 'America/Indianapolis' },
    { timeZone: 'America/Shiprock' },
    { timeZone: 'America/Yakutat' },
    { timeZone: 'America/Matamoros' },
    { timeZone: 'America/Catamarca' },
    { timeZone: 'America/St_Johns' },
    { timeZone: 'America/Tortola' },
    { timeZone: 'America/Vancouver' },
    { timeZone: 'America/Fort_Wayne' },
    { timeZone: 'America/Puerto_Rico' },
    { timeZone: 'America/Chicago' },
    { timeZone: 'America/Belize' },
    { timeZone: 'America/Recife' },
    { timeZone: 'America/Phoenix' },
    { timeZone: 'America/Edmonton' },
    { timeZone: 'America/Port-au-Prince' },
    { timeZone: 'America/Aruba' },
    { timeZone: 'America/Mexico_City' },
    { timeZone: 'America/Panama' },
    { timeZone: 'America/Dominica' },
    { timeZone: 'America/Menominee' },
    { timeZone: 'America/Port_of_Spain' },
    { timeZone: 'HST' },
    { timeZone: 'Pacific/Enderbury' },
    { timeZone: 'Pacific/Saipan' },
    { timeZone: 'Pacific/Apia' },
    { timeZone: 'Pacific/Pohnpei' },
    { timeZone: 'Pacific/Chuuk' },
    { timeZone: 'Pacific/Fakaofo' },
    { timeZone: 'Pacific/Ponape' },
    { timeZone: 'Pacific/Pago_Pago' },
    { timeZone: 'Pacific/Guadalcanal' },
    { timeZone: 'Pacific/Niue' },
    { timeZone: 'Pacific/Pitcairn' },
    { timeZone: 'Pacific/Tarawa' },
    { timeZone: 'Pacific/Honolulu' },
    { timeZone: 'Pacific/Port_Moresby' },
    { timeZone: 'Pacific/Midway' },
    { timeZone: 'Pacific/Samoa' },
    { timeZone: 'Pacific/Majuro' },
    { timeZone: 'Pacific/Noumea' },
    { timeZone: 'Pacific/Gambier' },
    { timeZone: 'Pacific/Guam' },
    { timeZone: 'Pacific/Kosrae' },
    { timeZone: 'Pacific/Marquesas' },
    { timeZone: 'Pacific/Kiritimati' },
    { timeZone: 'Pacific/Norfolk' },
    { timeZone: 'Pacific/Wallis' },
    { timeZone: 'Pacific/Bougainville' },
    { timeZone: 'Pacific/Auckland' },
    { timeZone: 'Pacific/Fiji' },
    { timeZone: 'Pacific/Chatham' },
    { timeZone: 'Pacific/Efate' },
    { timeZone: 'Pacific/Nauru' },
    { timeZone: 'Pacific/Palau' },
    { timeZone: 'Pacific/Rarotonga' },
    { timeZone: 'Pacific/Kwajalein' },
    { timeZone: 'Pacific/Easter' },
    { timeZone: 'Pacific/Johnston' },
    { timeZone: 'Pacific/Wake' },
    { timeZone: 'Pacific/Tahiti' },
    { timeZone: 'Pacific/Tongatapu' },
    { timeZone: 'Pacific/Truk' },
    { timeZone: 'Pacific/Galapagos' },
    { timeZone: 'Pacific/Yap' },
    { timeZone: 'Pacific/Funafuti' },
    { timeZone: 'Indian/Christmas' },
    { timeZone: 'Indian/Kerguelen' },
    { timeZone: 'Indian/Reunion' },
    { timeZone: 'Indian/Maldives' },
    { timeZone: 'Indian/Comoro' },
    { timeZone: 'Indian/Mahe' },
    { timeZone: 'Indian/Antananarivo' },
    { timeZone: 'Indian/Mayotte' },
    { timeZone: 'Indian/Chagos' },
    { timeZone: 'Indian/Cocos' },
    { timeZone: 'Indian/Mauritius' },
    { timeZone: 'Mexico/General' },
    { timeZone: 'Mexico/BajaSur' },
    { timeZone: 'Mexico/BajaNorte' },
    { timeZone: 'UCT' },
    { timeZone: 'Egypt' },
    { timeZone: 'Etc/GMT-12' },
    { timeZone: 'Etc/Zulu' },
    { timeZone: 'Etc/GMT-1' },
    { timeZone: 'Etc/GMT+5' },
    { timeZone: 'Etc/GMT-9' },
    { timeZone: 'Etc/GMT+10' },
    { timeZone: 'Etc/GMT+7' },
    { timeZone: 'Etc/GMT-2' },
    { timeZone: 'Etc/GMT-11' },
    { timeZone: 'Etc/GMT0' },
    { timeZone: 'Etc/GMT+9' },
    { timeZone: 'Etc/Universal' },
    { timeZone: 'Etc/GMT-10' },
    { timeZone: 'Etc/GMT+11' },
    { timeZone: 'Etc/GMT+1' },
    { timeZone: 'Etc/GMT-0' },
    { timeZone: 'Etc/GMT+4' },
    { timeZone: 'Etc/Greenwich' },
    { timeZone: 'Etc/GMT+0' },
    { timeZone: 'Etc/GMT-6' },
    { timeZone: 'Etc/GMT-5' },
    { timeZone: 'Etc/GMT+8' },
    { timeZone: 'Etc/GMT+2' },
    { timeZone: 'Etc/GMT+12' },
    { timeZone: 'Etc/GMT' },
    { timeZone: 'Etc/GMT-4' },
    { timeZone: 'Etc/GMT-14' },
    { timeZone: 'Etc/GMT-3' },
    { timeZone: 'Etc/GMT-8' },
    { timeZone: 'Etc/GMT-7' },
    { timeZone: 'Etc/GMT+3' },
    { timeZone: 'Etc/GMT+6' },
    { timeZone: 'Etc/UCT' },
    { timeZone: 'Etc/GMT-13' },
    { timeZone: 'Etc/UTC' },
    { timeZone: 'Singapore' },
    { timeZone: 'Atlantic/Faroe' },
    { timeZone: 'Atlantic/Faeroe' },
    { timeZone: 'Atlantic/Azores' },
    { timeZone: 'Atlantic/Madeira' },
    { timeZone: 'Atlantic/Canary' },
    { timeZone: 'Atlantic/Reykjavik' },
    { timeZone: 'Atlantic/Cape_Verde' },
    { timeZone: 'Atlantic/Stanley' },
    { timeZone: 'Atlantic/St_Helena' },
    { timeZone: 'Atlantic/South_Georgia' },
    { timeZone: 'Atlantic/Bermuda' },
    { timeZone: 'Atlantic/Jan_Mayen' },
    { timeZone: 'US/Aleutian' },
    { timeZone: 'US/East-Indiana' },
    { timeZone: 'US/Arizona' },
    { timeZone: 'US/Samoa' },
    { timeZone: 'US/Alaska' },
    { timeZone: 'US/Eastern' },
    { timeZone: 'US/Mountain' },
    { timeZone: 'US/Michigan' },
    { timeZone: 'US/Pacific' },
    { timeZone: 'US/Hawaii' },
    { timeZone: 'US/Central' },
    { timeZone: 'US/Indiana-Starke' },
    { timeZone: 'US/Pacific-New' },
    { timeZone: 'UTC' },
    { timeZone: 'ROC' }
  ];
}
