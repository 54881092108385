import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenStorageService } from '../../../src/app/_services/token-storage.service';
import { AuthService } from "../../app/_services/auth.service";
import { APIService } from '../_services/api.service';


@Injectable()
export class AuthGuard implements CanActivate {
    loginUserData: any;
    account_type:any;
    constructor(private router: Router,
    public tokenStorage:TokenStorageService,
    public apiService:APIService,
    public authService:AuthService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const array = [ "/reports-statistics/whatsapp-broadcast-report","/reports-statistics/whatsapp-report"]
        if (localStorage.getItem('currentUser')) {
          // logged in so return true
     
          return true;
      }
            // this.account_type = JSON.parse(localStorage.getItem('currentUser'));
            // if(this.account_type === null) {
            //     this.router.navigate(['/login']);
            //     return false;
            // }
            // console.log(JSON.parse(localStorage.getItem('currentUser')).account_type    , "acount_datatatata")
        // if(this.account_type && array.includes(state.url))

        // {
        //  console.log('Found')
        // }else{
        //  console.log("found not")
        // }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     this.loginUserData = this.tokenStorage.getAccountType()
           
    //     if (localStorage.getItem('currentUser')) {
    //         const allowedRoles = route.data.allowedRoles;
    //         if (allowedRoles != undefined) {
    //             if (this.loginUserData && allowedRoles.includes(this.loginUserData.account_type)) {
    //                 return true;
    //             } else {
    //                 if(this.loginUserData != null) {
    //                     this.router.navigate(['/dashboard']);
    //                 return false;
    //                 } else {
    //                 this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //                 return false;
    //                 }
    //             }
    //         }
    //         else {
    //             this.logout();
    //             this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //             return false;
    //         }
    //     } else {
    //         this.logout();
    //         this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //         return false;
    //     }
    //     return false;
    // }
    logout(){
        if(this.tokenStorage.getAccountType()=== 'Agent' || this.tokenStorage.getAccountType()=== 'Supervisor'){
            const socialParam = {
                condition: "refresh",
                agent_uuid: this.tokenStorage.getAccountType().agent_domain_uuid
              }
              this.apiService.socialMediaLogout(socialParam).subscribe((response) => {
              });
              // social set refresh ends here  
              const requestDta = {
                agent_uuid: this.tokenStorage.getAccountType().agent_domain_uuid,
                break_name: "Logged Out",
                domain_uuid: this.tokenStorage.getAccountType().domain_uuid,
              };
              this.apiService.requestBreak(requestDta).subscribe((response) => {
                if (response["Status"] == 1) {
                  this.authService.doLogout();
              // force logout start 
                  const update_agent_status_data = {
                    agent_uuid: this.tokenStorage.getAccountType().agent_domain_uuid,
                    status: 'Logged Out'
                  };
                  this.apiService.forceLogout(update_agent_status_data).subscribe((response) => { });
                } else {
                }
              });
        }
 
    }
}