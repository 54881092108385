import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html'
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  submitted = false;
  saveLoading = false;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private apiService: APIService) { }

  ngOnInit(): void {
    this.forgetPasswordForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]]
    })
  }

  get formAlert() { return this.forgetPasswordForm.controls }
  
  submit() {
    this.saveLoading = true;
    this.submitted = true;
    if (this.forgetPasswordForm.invalid) {
      this.saveLoading = false;
      return
    }
    const data = {
      email: this.forgetPasswordForm.value['emailAddress']
    };
    this.apiService.forgetPassword(data).subscribe((response) => {
      this.saveLoading = false;
      if (response && response['Status'] === '0') {
        this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
      } else {
        this.toastr.success(response['Msg'], 'Success!', { progressBar: true });
        sessionStorage.setItem('email_id', this.forgetPasswordForm.value['emailAddress']);
        this.router.navigateByUrl('change-password');
      }
    });
  }
}
