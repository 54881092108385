import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIService } from '../_services/api.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html'
})
export class OtpVerificationComponent implements OnInit {
  otpVerifyForm: FormGroup
  submitted = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private apiservices: APIService
  ) { }

  ngOnInit(): void {
    this.otpVerifyForm = this.fb.group({
      otp: ['']
    })
  }

  get formAlert() { return this.otpVerifyForm.controls }

  submit() {
    this.submitted = true;
    if (this.otpVerifyForm.invalid) {
      return
    }
    const data = {
      email: 'abc@gmail.com',
      secret_code: this.otpVerifyForm.value['otp']
    }
    this.apiservices.otpVerify(data).subscribe((response) => {
      if (response && response['Status'] === '0') {
        this.router.navigateByUrl('change-password')
      } else {
      }
    })
  }
}
