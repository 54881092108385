import { Component, OnInit } from '@angular/core';
import { APIService } from "../../../_services/api.service";
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from "@angular/common";
declare var $: any;
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebsocketService } from "../../../_services/websocket.service";

@Component({
  selector: 'app-internal-chat',
  templateUrl: './internal-chat.component.html',
  styleUrls: ['./internal-chat.component.scss']
})
export class InternalChatComponent implements OnInit {
  firstChatInitiateData: any;
  availableAgentsForChat: any;
  dataOfUser: any;
  showSmsWidget = false;
  // contactChatting: any;
  public contactChat = [];
  total_messages_count: any;
  ChattingMessages: any;
  smsChatting: any;
  refreshChatContacts: any;
  clearAgentsList: any;
  clearRecentChatList: any;
  refreshContactMessages: any;
  customer_count: any;
  newMessage = '';
  threadId: any;
  receiverExtension: any;
  receiverName: any;
  public internal_contacts = [];
  smsChatList = true;
  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private websocket: WebsocketService,
    public datepipe: DatePipe,
  ) {

  }

  ngOnInit(): void {
    this.dataOfUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.dataOfUser.account_type === 'Agent' || this.dataOfUser.account_type === 'Supervisor') {
      this.showSmsWidget = true;
      this.openAvailableAgent();
      this.openAvailableAgentSocket();
      this.getInternalContacts();
      this.getInternalContactsSocket();
    }
  }
  // recentChatTitle(){
  // }
  // availableAgentsTitle(){
  //   // alert("dncjsdkchkj")
  // }
  openAvailableAgent() {
    // this.clearAgentsList = setInterval(() => {
      const params = {
        from_id: this.dataOfUser.agent_domain_uuid,
        extension: this.dataOfUser.agent_id,
        domain_name: this.dataOfUser.domain_name
      }
      this.apiService.getAvailableChatAgent(params).subscribe((response) => {
        if (response['Status'] == 0) {
          this.availableAgentsForChat = '';
        } else {
          this.availableAgentsForChat = response['GetLiveAgents']
        }
      })
    // }, 3000);

  }

  openAvailableAgentSocket(){
    this.websocket.listen('get-live-agent').subscribe((data) => {
      console.log("get-live-agent", data['GetLiveAgents'].length);
      console.log("get-live-agent", data['GetLiveAgents']);
      if (data['GetLiveAgents'].length === 0){
        this.availableAgentsForChat = '';
      }else{
        this.availableAgentsForChat = data['GetLiveAgents']
      }
        this.availableAgentsForChat = data['GetLiveAgents']
    });
  }

  initiateChat(data) {
    const param = {
      from_id: this.dataOfUser.agent_domain_uuid,
      to_no: data.agent_id,
      first_name: this.dataOfUser.agent_name,
      last_name: "",
      phone_number: this.dataOfUser.agent_id,
      email: this.dataOfUser.email,
      time_zone: this.dataOfUser.timezone,
      domain_name: this.dataOfUser.domain_name,
      to_agent_name: data.agent_name
    }

    this.apiService.initiateInternalChat(param).subscribe((response: any) => {
      if (response['Status'] == 1) {
        this.firstChatInitiateData = response["internal_chats"];
        this.threadId = this.firstChatInitiateData[0].thread_id;
        this.receiverName = this.firstChatInitiateData[0].to_agent_name;
        this.receiveFirstChatMessages();
        this.receiveChatMessages();
        $("#SmsBox").show();
        // $("#smsChatList").hide();
        this.smsChatList = false;

      } else {
        // this.firstChatInitiateData = response["internal_chats"];
        // this.threadId = response["internal_chats"].thread_id;
        // this.receiverName = response['internal_chats'].first_name;
        // this.modalService.dismissAll();
        // $("#SmsBox").show();
        // $("#smsChatList").hide();
      }
    });
  }

  // Recent Chat contact list start here 
  getInternalContacts() {
    // this.clearRecentChatList = setInterval(() => {
    const param = {
      from_id: this.dataOfUser.agent_domain_uuid,
      extension: this.dataOfUser.agent_id,
      domain_name: this.dataOfUser.domain_name
    }
    this.apiService.getInternalChatAgent(param).subscribe((response) => {
      if (response['internal_chats'] != null) {
        if (this.customer_count != response['internal_chats'].length) {
          this.internal_contacts = response['internal_chats'];
          console.log(this.internal_contacts, "internal")
          this.customer_count = response['internal_chats'].length;
          console.log("data--->",response['internal_chats']);
        }
      } else {
        this.internal_contacts = [];
        this.customer_count = 0;
      }
    })
    // }, 3000);
  }



  getInternalContactsSocket() {
    this.websocket.listen('get-internal-agents').subscribe((data) => {
      this.internal_contacts = data['internal_chats'];
      this.customer_count = data['internal_chats'].length;
      console.log("internal_agents", data['internal_chats']);
    });
  }

  // Recent chat contact list ends here 
  //***************** SMS ********************* */
  clickSMSContact(data) {
    $("#SmsBox").show();
    // $("#smsChatList").hide();
    this.smsChatList = false;
    // this.smsStatusTimer()
    this.threadId = data.thread_id;
    this.receiverExtension = data.phone_number;
    this.receiverName = data.first_name;
    this.receiveChatMessages();
    this.receiveFirstChatMessages();
    this.getInternalContacts();
    this.getInternalContactsSocket();
  }

  receiveFirstChatMessages() {
    // this.refreshContactMessages =   setInterval(()=>{
    //     const params = {
    //       from_id:this.dataOfUser.agent_domain_uuid,
    //       thread_id:this.threadId,
    //       domain_name:this.dataOfUser.domain_name
    //     }
    //     this.apiService.receiveInternalChat(params).subscribe((response)=>{
    //     if (response['Status'] == 1) {
    //       if (this.total_messages_count != response['messages'].length) {
    //       this.contactChat = response['messages'];
    //         this.total_messages_count = this.contactChat.length;
    //         this.scrollToBottom();
    //       }
    //       this.scrollToBottom();
    //       this.total_messages_count = this.contactChat.length;
    //     } else {
    //       this.contactChat = [];
    //     }
    //     })
    //   },5000)
    const params = {
      from_id: this.dataOfUser.agent_domain_uuid,
      thread_id: this.threadId,
      domain_name: this.dataOfUser.domain_name
    }
    this.apiService.receiveInternalChat(params).subscribe((response) => {
      console.log(response, "response")
      if (response['Status'] == 1) {
        this.contactChat = response['messages'];
        console.log("--->", this.contactChat);
        this.scrollToBottom();
        this.total_messages_count = this.contactChat.length;
      } else {
        this.contactChat = [];
      }
    })

  }


  receiveChatMessages() {
    // const params = {
    //   from_id:this.dataOfUser.agent_domain_uuid,
    //   thread_id:this.threadId,
    //   domain_name:this.dataOfUser.domain_name
    // }
    // this.apiService.receiveInternalChat(params).subscribe((response)=>{
    // console.log(response  , "response")
    // if (response['Status'] == 1) {
    //   this.contactChat = response['messages'];
    //   console.log("--->",this.contactChat);
    //   this.scrollToBottom();
    //   this.total_messages_count = this.contactChat.length;
    // } else {
    //   this.contactChat = [];
    // }
    // })
    // this.refreshreceiveChatMessages();

    this.websocket.listen('recieve-internal-message').subscribe((data) => {
      this.contactChat = data['message'];
      console.log("socket data ->", data['message'])
      this.scrollToBottom();
    });

  }




  endChat(data) {
    this.apiService.deleteInternalChat(data, this.dataOfUser.domain_name).subscribe((res) => {
      if (res["Status"] == 1) {
        this.toastr.success(res["Msg"], "Success!", { progressBar: true });
        // clearInterval(this.refreshContactMessages);
        $("#SmsBox").hide();
        // $("#smsChatList").show();
        this.smsChatList = true;
        this.getInternalContacts();
        this.getInternalContactsSocket();
        this.openAvailableAgent();
        this.openAvailableAgentSocket();
      } else {
        this.toastr.error(res["Msg"], "Error!", { progressBar: true });
      }
    })

  }
  backToSMSContactList() {
    $("#SmsBox").hide();
    // $("#smsChatList").show();
    this.smsChatList = true;
    this.getInternalContacts();
    this.getInternalContactsSocket();
    this.openAvailableAgent();
    this.openAvailableAgentSocket();
    // clearInterval(this.refreshContactMessages)
  }
  // --------For Whatsapp-------

  anotherWhatsappEmojiSection() {
    $("#iconWhatsappContainerId").show();
    $("#openWhatsappPlusIcon").hide();
    $("#closeWhatsappPlusIcon").show();
  }
  closeWhatsappEmojiSection() {
    $("#openWhatsappPlusIcon").show();
    $("#iconWhatsappContainerId").hide();
    $("#closeWhatsappPlusIcon").hide();
  }

  sendChattingMessage() {
    const currentDate = new Date();
    if (this.newMessage !== null && this.newMessage !== '') {
      this.send_messages_to_agent(this.newMessage);
      this.contactChat.push({
        senderImage: '',
        message: this.newMessage,
        sender: this.dataOfUser.agent_id,
        timestamp: currentDate,
      });
      this.newMessage = null;
    }
    this.scrollToBottom();
  }


  onEnter(value: string) {
    const currentDate = new Date();
    this.newMessage = value;
    if (this.newMessage !== null && this.newMessage !== '') {
      this.send_messages_to_agent(this.newMessage);
      this.contactChat.push({
        senderImage: '',
        message: this.newMessage,
        sender: this.dataOfUser.agent_id,
        timestamp: currentDate,
      });
      this.newMessage = null;
    }
    this.scrollToBottom();
  }

  send_messages_to_agent(message) {
    const params = {
      from_id: this.dataOfUser.agent_domain_uuid,
      thread_id: this.threadId,
      sender: this.dataOfUser.agent_id,
      message: message,
      domain_name: this.dataOfUser.domain_name
    }
    this.apiService.sendInternalChat(params).subscribe((response) => {
      this.scrollToBottom();
    });
  }

  scrollToBottom = () => {
    const params = {
      thread_id: this.threadId
    }
    setTimeout(() => {
      var objDiv = document.getElementById("contentDiv");
      this.websocket.emit("update-internal-message-status", params);
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 500);
  }

  // toogle sms widget section start from here 
  toggleSMSWidget() {
    const element = document.querySelector('#smsWidgetForm');
    const style = getComputedStyle(element);

    this.getInternalContacts();
    this.getInternalContactsSocket();
    this.openAvailableAgent();
    this.openAvailableAgentSocket();
    if (style.display === 'block') {
      document.getElementById("smsWidgetForm").style.display = "none";
    } else {
      document.getElementById("smsWidgetForm").style.display = "block";
    }
  }
  // toogle sms widget section ends from here 
}
